import React from 'react';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';

interface CommonTooltipProps {
  title: string;
  children?: ReactNode;
}
export const CommonTooltip: React.FC<CommonTooltipProps> = ({
  title,
  children,
}) => {
  return <Tooltip title={title}>{children}</Tooltip>;
};

export default CommonTooltip;
