// Breadcrumb.jsx
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { BREADCRUMBPATHS } from '../utils/constants/Breadcrumb.tsx';

const MyBreadcrumb = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const paths = BREADCRUMBPATHS(t);
  const [tab, setTab] = useState('');
  // const currentPaths = location?.pathname
  //   .split('/')
  //   .filter((i) => i)
  //   .pop();
  const currentPathss = location?.pathname.split('/');
  // console.log('the pthssss', currentPathss);
  const currentPaths = currentPathss[1];
  const urlParams = new URLSearchParams(location.search);
  useEffect(() => {
    setTab(() => null);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(tabFromUrl);
    }
  }, [location.search]);
  const currentPath = currentPaths && currentPaths.trim().toLowerCase();
  let breadcrumbItems = [];

  // Find the breadcrumb path for the current URL

  let pathData;
  if (tab) {
    pathData = paths[tab];
  } else {
    pathData = paths[currentPath];
  }
  // console.log('path1====', pathData);
  // If pathData exists, assign it to breadcrumbItems
  if (
    currentPath === 'sign-in' ||
    currentPath === 'sign-up' ||
    currentPath === undefined
  ) {
    breadcrumbItems = [];
  } else if (pathData) {
    breadcrumbItems = pathData;
  } else {
    // Fallback if no matching key found
    breadcrumbItems = [{ title: t('home'), link: '/' }];
  }
  const shouldDisplayBreadcrumb =
    breadcrumbItems.length > 0 && currentPath !== '';
  return (
    <>
      {shouldDisplayBreadcrumb && (
        <Breadcrumb
          items={breadcrumbItems}
          style={{ margin: '10px' }}
          separator="&rarr;"
        />
      )}
    </>
  );
};

export default MyBreadcrumb;
