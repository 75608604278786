import Header from './Header.jsx';
import Footer from './Footer.jsx';
import DashSidebar from './DashSidebar.jsx';
import { BrowserRouter } from 'react-router-dom';
import { useState, Suspense } from 'react';
import { Layout } from 'antd';
import MaintenancePage from '../pages/SpecialPages/MaintenancePage.jsx';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import ScrollToTop from '../components/ScrollToTop.jsx';
import Breadcrumb from './Breadcrumb.jsx';
import Routes from '../routes/index.jsx';
// import LanguagePrompt from '../utils/languagePrompt';
import InstallPrompt from '../components/InstallPrompt.jsx';
const layout = () => {
  const [maintenanceEnabled] = useState(false);

  if (maintenanceEnabled) {
    return <MaintenancePage />;
  }
  const { Content } = Layout;

  const store = createStore({
    authName: '_auth',
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });
  return (
    <AuthProvider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <InstallPrompt />
          <Header />
          <Layout>
            <DashSidebar />
            <Layout style={{ position: 'aboslute' }}>
              <Content>
                <ScrollToTop />
                <Breadcrumb />
                <div className="min-h-screen ">
                  <Routes />
                </div>
              </Content>
              <Footer />
              {/* <LanguagePrompt /> */}
            </Layout>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default layout;
