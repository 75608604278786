import {
  Message,
  TextInput,
  Button,
  Select,
  // RadioGroup,
  Typography,
} from '../../utils/antd-components/index.tsx';

import { Form, Modal } from 'antd';
import { useState, useEffect } from 'react';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
// import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import routesnkt from '../../routes/routes.json';
import { IoAddCircleSharp } from 'react-icons/io5';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

export default function CreateUsersNew() {
  const { t } = useTranslation();
  // const [imageUploadError, setImageUploadError] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  // const [value, setValue] = useState(1);
  // const [usersList, setUsersList] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const authUser = useAuthUser();

  const roles = authUser.rolesType;

  const navigate = useNavigate();
  const [sendData, setSendData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    departmentId: '',
    roleId: '',
    designationId: '',
    status: 1,
  });
  const [form] = Form.useForm();
  const [successMessage, setsuccessMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [departmentList, setdepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [desiginationList, setDesiginationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        const URL = PATHS.DESIGINATION.GET + '?status=all&limit=1000';
        const data = await ApiUtils(URL);
        console.log(data.status);

        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          console.log('tset', data.data);
          setDesiginationList(data.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    const fetchrolesList = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        setLoading(true);
        const URL = `${PATHS.ROLES.GET}?${searchParams?.toString()}`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);

        if (data && data.status === 200) {
          console.log('role', data.response);
          setRoleList(data.response);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    const fetchprojectList = async (searchParams) => {
      try {
        setLoading(true);
        const URL = PATHS.DEPARTMENT.GET + `?${searchParams?.toString()}`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setdepartmentList(data.value);
          console.log('data+++', data.value);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('err in dash department', error.message);
      }
    };

    if (roles.includes('admin')) {
      fetchUsersList();
      fetchrolesList();
      fetchprojectList();
    }
  }, [roles]);

  const handleSubmit = async () => {
    // setLoading(true);
    // // e.preventDefault();
    // setdepartmentList();
    // setDesiginationList();
    // setRoleList();// need to delete

    // setPublishError(null);
    try {
      // const payload = { formData  };
      console.log('STAR+++++++==', sendData);

      const URL = PATHS.USER.POST;
      const data = await ApiUtils(URL, sendData, METHOD_TYPES.POST);
      setLoading(true);
      if (data.status !== 200) {
        setPublishError(data.message);
        setLoading(false);
        return;
      }

      if (data.status === 200) {
        setsuccessMessage(data.response.message);
        setPublishError(null);
        navigate(routesnkt.users);
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleDesiginationOk = async () => {
    if (!newModuleName || newModuleName.length < 3) {
      setError('Module name must be at least 3 characters long');
      return;
    }
    try {
      const payload = {
        title: newModuleName,
        status: 1,

        // name: newModuleName, // Set your new module name here
        // projectId: formData.projectId, // Use the selected project ID
        // userId: formData.userId, // Use the selected user ID
      };
      const URL = PATHS.DESIGINATION.ADD;
      const data = await ApiUtils(URL, payload, 'POST');
      if (data.status !== 200) {
        setPublishError(data.message);
      } else {
        // Add the new module to the list or update it in your state
        setPublishError(null);

        setDesiginationList((desiginationList) => [
          ...desiginationList,
          data.response,
        ]);
        console.log('data.response', data.response);
        console.log('data.response', desiginationList);
        console.log('befire ok ', formData);

        // setDesiginationList(...desiginationList, data.response);
        setFormData({
          ...formData,
          title: data.response.title,
          designationId: data.response._id,
        });
        console.log('after ok ', formData);
        setSendData({ ...sendData, designationId: data.response._id });
        form.setFieldsValue({
          // ...formData,

          title: data.response.title,
          designationId: data.response._id,
        });
        console.log('after ok ', formData);
      }
    } catch (error) {
      setPublishError('Error adding module');
    }

    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  const handleAddDesiginationClick = () => {
    setIsModalVisible(true);
  };

  const handleFirstName = (e) => {
    setSendData({ ...sendData, first_name: e.target.value });
    setFormData({ ...formData, first_name: e.target.value });
  };
  const handleLastName = (e) => {
    setSendData({ ...sendData, last_name: e.target.value });
    setFormData({ ...formData, last_name: e.target.value });
  };
  const handleEmail = (e) => {
    setSendData({ ...sendData, email: e.target.value });
    setFormData({ ...formData, email: e.target.value });
  };
  const handleDepartment = (e) => {
    console.log('dep', e);
    setSendData({ ...sendData, departmentId: e });
    setFormData({ ...formData, departmentId: e });
  };
  const handleRole = (e) => {
    setSendData({ ...sendData, roleId: e });

    console.log('role', e);
    setFormData({ ...formData, roleId: e });
  };
  const handleDesigination = (e) => {
    setSendData({ ...sendData, designationId: e });

    setFormData({
      ...formData,
      designationId: e,
      title: desiginationList.find((des) => {
        des._id == e;
      }),
    });
  };

  window.scrollTo(0, 0);
  return (
    <div className="p-3 max-w-5xl mx-auto min-h-screen flex flex-col">
      <TitleAndBackComponent title={t('createaUser')} goBackText={t('back')} />
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={formData}
        layout="vertical"
      >
        <div className="flex flex-row gap-4 justify-between mt-10">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('firstname')} />
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: t('First name is required') }]}
            >
              <TextInput
                type="text"
                placeholder={t('Enterfirstname')}
                required
                onChange={handleFirstName}
                id="first_name"
                className="flex-1"
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('lastname')} />
            <Form.Item
              // name="last_name"
              // rules={[{ required: true, message: t('Last name is required') }]}, <Form.Item
              name="last_name"
              rules={[
                { required: true, message: t('Last name is required') },
                {
                  validator: (_, value) => {
                    if (/\d/.test(value)) {
                      return Promise.reject(
                        t('Last name cannot contain numbers '),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              // >
            >
              <TextInput
                type="text"
                placeholder={t('Enterlastname')}
                onChange={handleLastName}
                id="last_name"
                className="flex-1"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between mt-200">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('email')} />
            <Form.Item
              name="personal_Email"
              rules={[
                {
                  required: true,
                  message: t('Email Required'),
                },
                {
                  type: 'email',
                  message: t('Please enter a valid email address'),
                },
              ]}
            >
              <TextInput
                type="email"
                onChange={handleEmail}
                placeholder={t('Enter email')}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-3">
            <Typography value={t('roleName')} />
            <Form.Item
              name="type"
              rules={[{ required: true, message: t('Role Name Required') }]}
            >
              <Select
                type="text"
                onChange={handleRole}
                // selectedValue = {formData.type}
                options={roleList}
                required={true}
                label="type"
                id={'_id'}
                placeholder={t('selectaRole')}
                className="h-10"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between mt-200">
          <div className="w-full flex flex-col gap-3 ">
            <Typography value={t('department')} />
            <Form.Item
              name="dept_name"
              rules={[{ required: true, message: t('Department Required') }]}
            >
              <Select
                onChange={handleDepartment}
                required={true}
                options={departmentList}
                placeholder={t('selectDepart')}
                id={'_id'}
                label="dept_name"
                className="flex-grow h-10"
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div style={{ display: 'flex', gap: 10, color: 'green' }}>
              <Typography value={t('designation')} />{' '}
              <IoAddCircleSharp
                style={{ fontSize: 25 }}
                onClick={handleAddDesiginationClick}
              />
            </div>
            <Form.Item
              name="title"
              rules={[{ required: true, message: t('Desigination Required') }]}
            >
              {/* <div style={{ display: 'flex', gap: 10 }}> */}
              <Select
                required={true}
                onChange={handleDesigination}
                selectedValue={formData?.title || formData?.designationId}
                options={desiginationList}
                placeholder={t('selectDesigination')}
                label={'title'}
                id={'_id'}
                className={'w-full h-10'}
              />
              {/* </div> */}
            </Form.Item>
          </div>
          <Modal
            title={t('Add New Desgination')}
            visible={isModalVisible}
            onOk={handleDesiginationOk}
            onCancel={handleModalCancel}
          >
            <TextInput
              type="text"
              placeholder={t('designation')}
              value={newModuleName}
              onChange={(e) => setNewModuleName(e.target.value)}
            />
            {error && <p className="text-red-500">{error}</p>}
          </Modal>
        </div>
        <div className="w-full flex flex-row justify-end my-1 py-2 mt-5">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            // onClick={handleSubmit}
          >
            {t('createaUser')}
          </Button>
        </div>
        {/* <Form> */}
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </Form>
    </div>
  );
}
