import React, { FC, ReactNode } from 'react';
import { Tag } from 'antd';

interface CustomTagProps {
  active?: string;
  children: ReactNode;
}

export const CustomTag: FC<CustomTagProps> = ({ active, children }) => {
  let color: string = 'default';

  if (active === '1') {
    color = 'green';
  } else if (active === '2') {
    color = 'blue';
  } else if (active === '3') {
    color = 'red';
  } else if (active === '4') {
    color = 'brown';
  } else if (active === '5') {
    color = 'yellow';
  } else if (active === '6') {
    color = 'orange';
  }

  return <Tag color={color}>{children}</Tag>;
};
