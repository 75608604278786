import {
  Message,
  Button,
  DatePicker,
  Typography,
  TimePicker,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import dayjs from 'dayjs';
import { Form } from 'antd';
import routesnkt from '../../routes/routes.json';

const UpdateUserTimings = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const { postId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchUserTimings = async () => {
        const URL = `${PATHS.USER.USERTIMINGS}?_id=${postId}`;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.response[0]);
          form.setFieldsValue(data.response[0]);
        }
      };

      fetchUserTimings();
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = {
        ...e,
        userId:
          typeof formData.userId === 'object'
            ? formData.userId._id
            : formData.userId,
      };
      setLoading(true);

      if (roles.includes('admin')) {
        const URL = `${PATHS.USER.USERTIMINGSUPDATE}/${formData._id}`;
        const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.clockIns);
        }
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <TitleAndBackComponent
        title={t('UpdateUserTimings')}
        goBackText={t('back')}
      />
      <Form
        form={form}
        className="flex flex-col gap-4"
        onFinish={handleSubmit}
        initialValues={{
          date: formData.createdAt ? dayjs(formData.createdAt) : null,
          clocksInTime: formData.clocksInTime
            ? dayjs(formData.clocksInTime)
            : null,
          clocksOutTime: formData.clocksOutTime
            ? dayjs(formData.clocksOutTime)
            : null,
        }}
      >
        <FloatButton.BackTop />
        <div className="flex flex-col gap-2 mt-4 ">
          <Typography value={t('date')} />
          <Form.Item name="createdAt" rules={[{ required: true }]}>
            <div className="w-full flex flex-col gap-2">
              <DatePicker
                placeholder={t('select-date')}
                format="DD-MM-YYYY"
                required
                id="date"
                ReadOnly={true}
                className="h-10  w-full"
                value={dayjs(formData.createdAt)}
                onChange={(e) => {
                  setFormData({ ...formData, createdAt: e }),
                    form.setFieldsValue({ createdAt: e });
                }}
              />
            </div>
          </Form.Item>
        </div>
        <div className="flex flex-row gap-4 justify-between mt-3">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('clocked-in-0')} />
            <Form.Item name="clocksInTime" rules={[{ required: true }]}>
              <div className="w-full flex flex-col gap-2">
                <TimePicker
                  id={'clocked-in-0'}
                  className={'h-10 w-full'}
                  onChange={(e) => {
                    setFormData({ ...formData, clocksInTime: e }),
                      form.setFieldValue('clocksInTime', e);
                  }}
                  selectedValue={
                    formData?.clocksInTime && dayjs(formData?.clocksInTime)
                  }
                />
              </div>
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('clocked-out')} />
            <Form.Item name="clocksOutTime" rules={[{ required: true }]}>
              <div className="flex flex-row gap-2">
                <TimePicker
                  id={'clocked-out'}
                  className={'h-10 w-full'}
                  onChange={(e) => {
                    setFormData({ ...formData, clocksOutTime: e }),
                      form.setFieldsValue({ clocksOutTime: e });
                  }}
                  selectedValue={
                    formData?.clocksOutTime && dayjs(formData?.clocksOutTime)
                  }
                />
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="flex justify-end w-full">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('UpdateUserTimings')}
          </Button>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </Form>
    </div>
  );
};
export default UpdateUserTimings;
