import {
  Table,
  Select,
  Notification,
  Button,
  Typography,
  Tooltip,
  FloatButton,
  RangePicker,
} from '../../../utils/antd-components/index.tsx';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { PiExportBold } from 'react-icons/pi';
import { MdAddChart } from 'react-icons/md';
import dayjs from 'dayjs';
import SearchableColumn from '../../../utils/reUsable/SearchableColumn.jsx';
import routesnkt from '../../../routes/routes.json';
import {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';
// import {
//   calculateTimeDifferenceInHours,
//   populate12HrFormat,
// } from '../utils/reUsable/index.jsx';

const workLogReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authUser = useAuthUser();
  const userId = authUser?._id;
  const roles = authUser.rolesType;
  const [modulesList, setModulesList] = useState([]);
  const [filterStatus, setStatusFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [projectId, setProject] = useState(null);
  const [moduleId, setModule] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [usersId, setUserId] = useState(null);
  const [usersList, setUsersList] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameFilterValue = searchParams.get('name');
    const statusFilterValue = searchParams.get('status');
    const startDateFilterValue = searchParams.get('startDate');
    const endDateFilterValue = searchParams.get('endDate');

    // Update state based on query parameters
    if (statusFilterValue && statusFilterValue !== 'all') {
      setStatusFilter(statusFilterValue);
    } else if (statusFilterValue === 'all') {
      setStatusFilter([]);
    }
    if (
      nameFilterValue &&
      nameFilterValue !== 'null' &&
      nameFilterValue !== 'undefined'
    ) {
      setSearchText(nameFilterValue);
    }

    if (startDateFilterValue && endDateFilterValue) {
      const dates = [dayjs(startDateFilterValue), dayjs(endDateFilterValue)];
      setDateRange(dates);
    }

    // Make API call based on query parameters
    if (userId) {
      fetchWorkLogList(searchParams);
    }
  }, [location.search, userId]);

  const fetchWorkLogList = async (searchParams) => {
    try {
      setLoading(true);
      setModulesList([]);
      setTotalCount(null);
      let URL = `${PATHS.WORKLOG.GET}?${searchParams.toString()}`;
      URL += `&limit=${pageSize}`;

      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setModulesList(data.response);
        setTotalCount(data.totalCount);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  // const handleChange = (selectedList) => {
  //   setProject(selectedList);
  // };
  const handleChange = (selectedProject) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedProject) {
      setProject(selectedProject);
      updateURL({
        ...Object.fromEntries(searchParams),
        projectId: selectedProject,
      });
    } else {
      setProject(null); // Clear the project state
      setModule(null); // Clear the module state
      // setSelectedUsers([]); // Clear the selected users
      updateURL({
        ...Object.fromEntries(searchParams),
        projectId: '',
        moduleId: '',
        userId: '',
      });
    }
  };

  // const handleChangeModule = (selectedList) => {
  //   setModule(selectedList);
  // };

  const handleChangeModule = (selectedList) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedList) {
      setModule(selectedList);
      updateURL({
        ...Object.fromEntries(searchParams),
        moduleId: selectedList,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        moduleId: '',
      });
    }
  };

  /*** Setting the status filter */
  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    if (statusFilterValue) {
      setStatusFilter(statusFilterValue);
      handleUrlFilter('status', statusFilterValue);
    }
  };
  /* Setting the status filter finished */

  /*** Setting the url for filter changes */
  const handleUrlFilter = async (key, value) => {
    console.log('value in handle search', key, value);
    try {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(key, value); // Set the value for the specified key
      updateURL(Object.fromEntries(searchParams));
    } catch (error) {
      console.log('err in handle search', error.message);
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };
  /* Setting the url for filter changes finished*/

  /*** Setting the date filter */
  const handleDateRangeChange = (dates) => {
    const searchParams = new URLSearchParams(location.search);
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setDateRange(dates);
      updateURL({
        ...Object.fromEntries(searchParams),
        startDate: dayjs(start).format('YYYY-MM-DD'), // Ensure correct format
        endDate: dayjs(end).format('YYYY-MM-DD'), // Ensure correct format
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        startDate: '',
        endDate: '',
      });
    }
  };
  /***  Setting the date filter finished */

  useEffect(() => {
    const fetchprojectList = async () => {
      try {
        setLoading(true);
        let URL;
        if (roles.includes('admin')) {
          URL = `${PATHS.SUBPROJECT.GET}?limit=1000`;
        } else {
          URL = `${PATHS.MODULES.PROJECT}`;
        }
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          setProjectList(data.response);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (userId) {
      fetchprojectList();
    }
  }, [userId]);

  useEffect(() => {
    const fetchModuleList = async () => {
      try {
        setLoading(true);
        let URL;
        if (projectId) {
          URL = `${PATHS.MODULES.GET}?projectId=${projectId}`;
        } else {
          URL = `${PATHS.MODULES.GET}`;
        }
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setModuleList(data.response);
        } else {
          setModuleList([]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
        setModuleList([]);
      }
    };

    if (userId) {
      fetchModuleList();
    }
  }, [userId, projectId]);

  useEffect(() => {
    const fetchWorkLogList = async () => {
      try {
        setLoading(true);
        setModulesList([]);
        setTotalCount(null);
        let URL = `${PATHS.WORKLOG.GET}?status=${filterStatus}&projectId=${projectId}&moduleId=${moduleId}`;

        URL += `&limit=${pageSize}`;
        if (roles.includes('admin')) {
          if (usersId) {
            URL += `&userId=${usersId}`;
          }
        } else {
          URL += `&userId=${userId}`;
        }

        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setModulesList(data.response);
          setTotalCount(data.totalCount);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (userId) {
      fetchWorkLogList();
    }
  }, [userId, filterStatus, projectId, usersId, moduleId]);

  // const emphandleChange = (selectedList) => {
  //   setSelectedUsers(selectedList);
  //   setUserId(selectedList);
  // };

  const emphandleChange = (selectedList) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedList) {
      // setSelectedUsers(selectedList);
      setUserId(selectedList);
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: selectedList,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: '',
      });
    }
  };

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.USER.GET + `?limit=1000`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setUsersList(data.users);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (roles?.includes('admin')) {
      fetchUsersList();
    }
  }, [userId]);

  const handleShowMore = async (page, pageSize) => {
    try {
      console.log('page', page, 'pageSize', pageSize);
      setPageCount(page);
      setPageSize(pageSize);
      setLoading(true);

      const searchParams = new URLSearchParams(location.search);
      let URL = `${PATHS.WORKLOG.GET}?${searchParams.toString()}&page=${page}&limit=${pageSize}&projectId=${projectId}&moduleId=${moduleId}`;
      URL += `&limit=${pageSize}&page=${page}`;
      if (roles.includes('admin')) {
        if (usersId) {
          URL += `&userId=${usersId}`;
        }
      }

      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);

      if (data.status === 200) {
        setModulesList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  /*** Adding delete functionality */
  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.WORKLOG.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setModulesList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        setSuccessMessage(null);
        setErrorMessage(data.message);
        console.log('err in del catagory', data.message);
      }
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(error.message);
      console.log('err in del catagory', error.message);
    }
  };
  /*** Adding delete functionality finished */

  // change tooltip text as string
  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

  /***  table column builder */
  const columns = [
    // {
    //   title: t('createdOn'),
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    // },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      render: (date) =>
        new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t('work-log-Title'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        const cleanContent = stripHtmlTags(record?.content);
        return <Tooltip title={cleanContent}>{text}</Tooltip>;
      },
      ...SearchableColumn({ dataIndex: 'name', searchText }),
    },
    {
      title: t('projectname'),
      dataIndex: ['projectId', 'name'],
      key: ['projectId', 'name'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('modulesName'),
      dataIndex: ['moduleId', 'name'],
      key: ['moduleId', 'name'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('hours'),
      dataIndex: 'time',
      key: 'time',
    },

    // {
    //   title: t('start-time'),
    //   render: (text, record) => (
    //     <div className="flex flex-row items-center">
    //       {populate12HrFormat(record.startTime)}
    //     </div>
    //   ),
    // },
    // {
    //   title: t('end-time'),
    //   render: (text, record) => (
    //     <div className="flex flex-row items-center">
    //       {populate12HrFormat(record.endTime)}
    //     </div>
    //   ),
    // },
    // {
    //   title: t('hours'),
    //   render: (text, record) => (
    //     <div className="flex flex-row items-center">
    //       {calculateTimeDifferenceInHours(record.startTime, record.endTime)}
    //     </div>
    //   ),
    // },
  ];

  if (roles.includes('admin')) {
    columns.push(getStatusColumn(t, filterStatus));
    columns.push({
      title: t('username'),
      dataIndex: ['userId', 'first_name'],
      key: ['userId', 'first_name'],
      // sorter: (a, b) => a.userId.localeCompare(b.userId),
    });
  }

  columns.push(getEditColumn(t, routesnkt.updateworklog));

  if (roles.includes('admin')) {
    columns.push(getDeleteColumn(t, handleDeleteUser));
  }
  /*** table column builder finished */

  /*** Function to export table data as Excel */
  const exportToExcel = () => {
    // Restrict some values and extract additional information
    const formattedModulesList = modulesList.map((module) => ({
      Date: new Date(module.date).toLocaleDateString(),
      Project: module.projectId.name,
      Module: module.moduleId.name,
      Status:
        module.status === 1
          ? 'Active'
          : module.status === 2
            ? 'Inactive'
            : 'Deleted',
      Time: module.time,
      User: module.userId.first_name,
      Title: module.name,
      Content: module.content ? module.content.replace(/<[^>]+>/g, '') : '-',
    }));

    // Sort formatted data by date in ascending order
    formattedModulesList.sort((a, b) => new Date(a.Date) - new Date(b.Date));

    // Convert formatted data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(formattedModulesList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ModulesList');

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const excelBlob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });

    // Save Excel file
    saveAs(excelBlob, 'TimeSheet.xlsx');
  };
  /*** Function to export selected rows as Excel Finished */

  return (
    <div className="w-full px-4 md:mx-auto p-3">
      <FloatButton.BackTop />
      <div className="w-full flex flex-col">
        <div className="flex flex-col md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
          {!roles.includes('admin') && (
            <div className="w-full md:w-1/3 flex flex-col gap-2">
              <Typography value={t('selectProject')} />
              <Select
                required={false}
                onChange={handleChange}
                options={projectList}
                placeholder={t('select')}
                label="projectId.name"
                id="projectId._id"
              />
            </div>
          )}

          {roles.includes('admin') && (
            <div className="w-full md:w-1/3 flex flex-col gap-2">
              <Typography value={t('selectProject')} />
              <Select
                required={false}
                onChange={handleChange}
                options={projectList}
                placeholder={t('select')}
                label="name"
                // id="projectId._id"
              />
            </div>
          )}
          <div className="w-full md:w-1/3 flex flex-col gap-2">
            <Typography value={t('selectModule')} />
            <Select
              required={false}
              onChange={handleChangeModule}
              // selectedValue={moduleId}
              options={moduleList}
              placeholder={t('select')}
              label="name"
              // id="_id"
              disabled={!projectId}
            />
          </div>
          {roles.includes('admin') && (
            <>
              <div className="w-full md:w-1/3 flex flex-col gap-2">
                <Typography value={t('selectUser')} />
                <Select
                  options={usersList}
                  className={'w-full'}
                  // selectedValue={selectedUsers}
                  onChange={emphandleChange}
                  placeholder={t('selectaUser')}
                  // mode={'multiple'}
                  // disabled={!projectId}
                />
              </div>
            </>
          )}
          <div className="w-full md:w-1/3 flex flex-col gap-2">
            <Typography value={t('select-range')} />
            <RangePicker
              onChange={handleDateRangeChange}
              selectedValue={dateRange}
            />
          </div>
          <div className="flex w-1/2 flex-row md:justify-between items-start gap-2">
            <div className="w-1/2 flex flex-col gap-2">
              <Link to={routesnkt.createWorklog}>
                <Button
                  type="button"
                  gradientDuoTone="purpleToBlue"
                  className="w-full items-center"
                  icon={MdAddChart()}
                >
                  {t('add_worklog')}
                </Button>
              </Link>
            </div>
            <div className="w-1/2 flex flex-col gap-2">
              <Button
                onClick={exportToExcel}
                className="w-full items-center"
                icon={PiExportBold()}
              >
                {t('export')}{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Table
        dataSource={modulesList}
        columns={columns}
        onChange={handleStatusFilterChange}
        pagination={{
          pageSizeOptions: ['10', '50', '100', '500'],
          showSizeChanger: true,
          pageSize: pageSize,
          total: totalCount,
          current: pageCount,
          onChange: (page, pageSize) => {
            handleShowMore(page, pageSize);
          },
        }}
        loading={loading}
      />
      {errorMessage && (
        <Notification successMessage={errorMessage} type="error" />
      )}
      {successMessage && (
        <Notification successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default workLogReport;
