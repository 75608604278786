import React, { FC } from 'react';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

interface CommonTabsProps extends Partial<TabsProps> {
  items: TabsProps['items'];
}

export const CommonTabs: FC<CommonTabsProps> = ({
  items,
  defaultActiveKey = '2',
}) => {
  return <Tabs defaultActiveKey={defaultActiveKey} items={items} />;
};
