import {
  Table,
  Message,
  Button,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import SearchableColumn from '../../../utils/reUsable/SearchableColumn.jsx';
import routesnkt from '../../../routes/routes.json';
import {
  getStatusColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';
import { CiEdit } from 'react-icons/ci';

const DashDepartment = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  const navigate = useNavigate();

  const [deparmentList, setdeparmentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameFilterValue = searchParams.get('name');
    const statusFilterValue = searchParams.get('status');
    if (statusFilterValue && statusFilterValue !== 'all') {
      let statusValue = parseInt(statusFilterValue);
      setSelectedStatus(statusValue);
    } else if (statusFilterValue === 'all') {
      setSelectedStatus([]);
    }
    if (
      nameFilterValue &&
      nameFilterValue !== 'null' &&
      nameFilterValue !== 'undefined'
    ) {
      setSearchText(nameFilterValue);
    }
    if (roles?.includes('admin')) {
      fetchprojectList(searchParams);
    }
  }, [location.search, userId]);

  const fetchprojectList = async (searchParams) => {
    try {
      setLoading(true);
      const URL = PATHS.DEPARTMENT.GET + `?${searchParams?.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setdeparmentList(data.value);
        console.log('data+++', data.value);
        setTotalCount(data.totalCount);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('err in dash department', error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    console.log('pagination', pagination);
    console.log('pagination1', filters);
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.DEPARTMENT.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setdeparmentList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        console.log('error in del temple=====', data);
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del temple1=====', error);
      setSuccessMessage(null);
      setErrorMessage(t('error-pls-try-again-0'));
    }
  };

  const columns = [
    {
      title: t('departmentname'),
      dataIndex: 'dept_name',
      key: 'dept_name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...SearchableColumn({ dataIndex: 'name', searchText }),
    },
    {
      title: t('selectdeparment'),
      dataIndex: 'lead_name',
      key: 'lead_name',
    },

    getStatusColumn(t, selectedStatus),
    // getEditColumn(t, routesnkt.updateDepartment),
    {
      title: t('edit'),
      key: 'edit',
      render: (text, record) => (
        <Link
          to={`${'/update-department/'}${record._id}`}
          state={{ record }} // Pass state as an object
          className="text-teal-500 hover:underline"
        >
          <CiEdit size={20} color="blue" />
        </Link>
      ),
    },
    getDeleteColumn(t, handleDeleteUser),
  ];

  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2 mt-5">
        <Link to={routesnkt.createDepartment}>
          <Button
            type="button"
            gradientDuoTone="purpleToBlue"
            className="w-full"
          >
            {t('addDepartment')}
          </Button>
        </Link>
      </div>
      <>
        {roles?.includes('admin') && (
          <Table
            dataSource={deparmentList}
            columns={columns}
            onChange={handleStatusFilterChange}
            pagination={{
              pageSize: 10,
              total: totalCount,
              current: pageCount,
            }}
            loading={loading}
          />
        )}
      </>
      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default DashDepartment;
