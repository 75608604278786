import {
  Message,
  Select,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import routesnkt from '../../routes/routes.json';

export default function UpdateDepartment() {
  const location = useLocation();
  const record = location.state;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishError, setPublishError] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [usersList, setUsersList] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);

  const postId = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('api call', record.record);

    const { dept_name, lead_name, description, ...restValues } = record.record;
    setFormData({
      dept_name: dept_name,
      lead_name: lead_name,
      description: description,
      restValues,
    });
    form.setFieldsValue({
      ...restValues,
      dept_name: dept_name,
      lead_name,
      description,
    });
    console.log('get data ', formData);
  }, []);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          // const filteredUsers = data.users.filter(
          //   (user) => user.roleId?.type === 'Project Manager',
          // );
          // setUsersList(filteredUsers);
          setUsersList(data.users);
        }
      };

      fetchUsers();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const payload = { ...e };
      console.log('post id*********************', postId.postId);
      const URL = `${PATHS.DEPARTMENT.UPDATE}/${postId.postId}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      setLoading(true);
      console.log('URLLLLL', URL);
      console.log('dataaaaaa', data);
      console.log('dataaaaaastatus', data.status);
      if (data.status === 200) {
        setsuccessMessage(
          data.response.message || 'Department updated successfully',
        );
        setPublishError(null);
        navigate(routesnkt.department);
      } else {
        setPublishError(data.message || 'Failed to update Department');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating Department:', error);
      setPublishError('Something went wrong');
    }
  };
  // useEffect(() => {
  //   if (formData.imageUrl) {
  //     form.setFieldsValue({ imageUrl: formData.imageUrl });
  //   }
  // }, [formData.imageUrl, form]);
  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('UpdateDepartment')}
        goBackText={t('back')}
      />
      <Form form={form} onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('departmentname')} />
            <Form.Item
              name="dept_name"
              rules={[
                { required: true, message: t('Department name is required') },
              ]}
            >
              <TextInput
                // value={formData.dept_name}
                placeholder={t('departmentname')}
              />
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectdeparment')} />
            <Form.Item
              name="lead_name"
              rules={[
                { required: true, message: t('Manager name is required') },
              ]}
            >
              <Select
                className={'h-10'}
                required={false}
                onChange={(value) => {
                  setFormData({ lead_name: value });
                }}
                selectedValue={formData.lead_name}
                options={usersList}
                placeholder={t('selectaUser')}
                label={'first_name'}
                id={'first_name'}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="description" rules={[{ required: false }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="flex flex-row gap-2 sm:flex-col justify-between ">
          <Typography value={t('select-status')} />
          <Form.Item name="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
            {/* <Radio.Group>
              <Radio value="1">{t('active')}</Radio>
              <Radio value="2">{t('inActive')}</Radio>
            </Radio.Group> */}
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('UpdateDepartment')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
