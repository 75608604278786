/* eslint-disable no-unused-vars */
import {
  Message,
  TextInput,
  Select,
  Button,
  RadioGroup,
  Phonenum as NumberInput,
  DatePicker,
  // Upload,
  Typography,
} from '../../utils/antd-components/index.tsx';
import { useSelector } from 'react-redux';
import { Steps, Form, Input, message, Radio, Upload } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import { FaUpload } from 'react-icons/fa6';
import { AiFillDelete } from 'react-icons/ai';
import { BiAddToQueue, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
import {
  MARITAL_STATUS,
  BLOOD_GROUP,
  GENDER,
} from '../../utils/constants/index.js';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import routesnkt from '../../routes/routes.json';
import ImageUpload from '../../utils/reUsable/ImageUpload.tsx';
import DefaultImage from '../../assets/images/default-user.png';
import dayjs from 'dayjs';

const { Step } = Steps;

export default function CreateUsersNew() {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const filePickerRef = useRef();
  const [form] = Form.useForm();
  const authUser = useAuthUser();

  const userId = authUser?._id;
  const roles = authUser?.rolesType;
  const [file, setFile] = useState(null);
  const [imageFileUploadProgress, setImageUploadProgress] = useState(null);
  const [imageFileUploadError, setImageUploadError] = useState(null);
  const [info, setInfo] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);

  const [imageFileUploading, setImageFileUploading] = useState(false);
  const { error } = useSelector((state) => state.user);
  const { postId } = useParams();
  const [formData, setFormData] = useState({
    status: 1,
    imageUrl: null,
    eduFields: [{ edu_level: '', org_name: '', percentage: '' }],
    expFields: [{ role_name: '', comp_name_: '', yr_of_experience: '' }],
    departmentId: '',
    manager_name: '',
    resume: '',
  });
  const userDetails = authUser.userDetails;
  const [value, setValue] = useState(1);
  const [usersList, setUsersList] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const navigate = useNavigate();
  const { Option } = Select;
  const [successMessage, setsuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState();
  const [selectedDOB, setSelectedDOB] = useState();
  const [selectedDOJ, setSelectedDOJ] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedMar_status, setSelectedMar_status] = useState();
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentId, setdepartmentId] = useState([]);
  const [designation, setdesignation] = useState([]);

  //for resume upload
  const [fileList, setFileList] = useState([]);
  const [resumeUploading, setResumeUploading] = useState(false);
  const [resumeUploadProgress, setResumeUploadProgress] = useState(null);
  const [resumeUploadError, setResumeUploadError] = useState(null);

  const [expFields, setexpFields] = useState([
    {
      role_name: ' ',
      comp_name: ' ',
      yr_of_experience: ' ',
    },
  ]);

  useEffect(() => {
    try {
      const fetchDept = async () => {
        const URL = PATHS.DEPARTMENT.GET_U;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setdepartmentId(data.value);
        }
      };

      fetchDept();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchdesignation = async () => {
        const URL = PATHS.DESIGINATION.GET_U;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setdesignation(data.data);
          console.log('de==========,,,,,,,,', designation);
        }
      };

      fetchdesignation();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  // Make API call based on query parameters

  useEffect(() => {
    if (formData.resume) {
      setFileList([
        {
          uid: '-1',
          name: 'Resume.pdf', // Use a default name or parse it from the URL if available
          status: 'done',
          url: formData.resume,
        },
      ]);
    }
  }, [formData.resume]);
  useEffect(() => {
    fetchusers();
  }, [postId]);

  const fetchusers = async (searchParams) => {
    try {
      // const URL = PATHS.USER.GET;
      let URL;
      if (roles.includes('admin')) {
        URL = PATHS.USER.GET;
      } else {
        URL = PATHS.USER.GET_U;
      }
      const data = await ApiUtils(URL + `?_id=${postId}&status=all`);

      if (data.status !== 200) {
        console.log(data.message);
        setPublishError(data.message);
        return;
      } else {
        setPublishError(null);
        console.log('user data>>>>>>>', data.users[0]);
        setFormData(data.users[0]);

        form.setFieldsValue({
          ...data.users[0],
          manager_name: data.users[0].departmentId.lead_name,
        });

        const formValues = {
          ...data.users[0],
          bank_name: data.users[0].bank_details.bank_name,
          account_no: data.users[0].bank_details.account_no,
          branch: data.users[0].bank_details.branch,
          ifsc: data.users[0].bank_details.ifsc,
          contact_address: data.users[0].emergency_details.contact_address,
          contact_name: data.users[0].emergency_details.contact_name,
          mobilenumber: data.users[0].emergency_details.mobile_number,
          relation: data.users[0].emergency_details.relation,
          departmentId: data.users[0].departmentId,
        };

        // Iterate over education array to set education details dynamically
        data.users[0].education.forEach((edu, index) => {
          formValues[`education.edu_level_${index}`] = edu.edu_level;
          formValues[`education.org_name_${index}`] = edu.org_name;
          formValues[`education.percentage_${index}`] = edu.percentage;
        });

        data.users[0].experience.forEach((exp, index1) => {
          formValues[`experience.role_name_${index1}`] = exp.role_name;
          formValues[`experience.comp_name_${index1}`] = exp.comp_name;
          formValues[`experience.yr_of_experience_${index1}`] =
            exp.yr_of_experience;
        });

        form.setFieldsValue(formValues);
        setexpFields(data.users[0].experience);
        form.setFieldsValue(formValues);
        setEduFields(data.users[0].education);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  console.log('education============', formData.education);
  //for experience dynamic fields

  const handleAddField = () => {
    setexpFields([
      ...expFields,
      { role_name: '', comp_name: '', yr_of_experience: '' },
    ]);
  };

  const handleRemoveField = (index) => {
    const newExpFields = expFields.filter((_, i) => i !== index);
    setexpFields(newExpFields);
  };

  // Education fields

  //for education dynamic fields

  const [eduFields, setEduFields] = useState([
    {
      edu_level: '',
      org_name: '',
      percentage: '',
      label: 'SSLC',
      placeholder: 'Enter SSLC',
    },
    {
      edu_level: '',
      org_name: '',
      percentage: '',
      label: 'HSC',
      placeholder: 'Enter HSC',
    },
    {
      edu_level: '',
      org_name: '',
      percentage: '',
      label: 'Degree',
      placeholder: 'Enter Degree',
    },
  ]);

  const handleAddEduField = () => {
    setEduFields([
      ...eduFields,
      {
        edu_level: '',
        org_name: '',
        percentage: '',
        label: `Qualification ${eduFields.length + 1}`,
        placeholder: `Enter Qualification ${eduFields.length + 1}`,
      },
    ]);
  };

  const handleRemoveEduField = (index1) => {
    const newEduFields = [...eduFields];
    newEduFields.splice(index1, 1);
    setEduFields(newEduFields);
  };

  useEffect(() => {
    try {
      const fetchPost = async () => {
        const URL = PATHS.ROLES.GET;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.response);
        }
      };

      fetchPost();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const {
        _id,
        userId,
        username,
        firebase,
        locations,
        createdAt,
        updatedAt,
        allValues,
        manager_name,
        email,
        // roleId,
        employeeId,
        ...postData
      } = formData;

      // let URL;
      // if (roles.includes('admin')) {
      //   URL = PATHS.USER.GET;
      // } else {
      //   URL = `${PATHS.USER.GET_U}?id=${postId}&limit=100`;
      // }
      let perviousexp = expFields.filter(
        (item) =>
          item?.comp_name?.trim() !== '' &&
          item?.role_name?.trim() !== '' &&
          item?.yr_of_experience,
      );
      console.log('data--', perviousexp);
      const URL = `${PATHS.USER.UPDATE}/${postId}`;
      const roleIdString = formData?.roleId?._id;
      console.log('post data=====', eduFields);
      const updatedFormData = {
        ...postData,
        // ...formData,
        lead_name: postData?.manager_name,
        departmentId: postData?.departmentId?._id || postData?.departmentId,
        designationId: postData?.designationId?._id || postData?.designationId,
        education: eduFields.map(({ edu_level, org_name, percentage }) => ({
          edu_level,
          org_name,
          percentage,
        })),
        roleId: roleIdString,
        experience: perviousexp.map(
          ({ role_name, comp_name, yr_of_experience }) => ({
            role_name,
            comp_name,
            yr_of_experience,
          }),
        ),
      };
      const data = await ApiUtils(URL, updatedFormData, METHOD_TYPES.PUT);

      if (data.status === 200) {
        setPublishError(null);
        navigate(routesnkt.users);
        if (roles.includes('admin')) {
          navigate(routesnkt.users);
        } else {
          navigate(routesnkt.profile);
        }
      } else {
        setPublishError(data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setPublishError(t('something-went-wrong'));
    } finally {
      setLoading(false);
    }
  };

  // console.log('datatatauy029012210>>>>>>', info);

  // const next = () => {
  //   setCurrentStep(currentStep + 1);
  //   window.scrollTo(0, 0);
  // };
  // );
  // const da = form.validateFields((err, values) => {
  //   if (!err) {
  //     console.log('Received values of form: ', values);
  //   }
  //   console.log('Received values of form: ', values);
  // });
  const next = () => {
    form
      .validateFields()
      .then((s) => {
        // console.log('erererererererer=========', formData);
        // message.success('Validation passed, proceeding to next step!');
        setCurrentStep(currentStep + 1);
        window.scrollTo(0, 0);
        setFormData(formData);

        // console.log('datattatayayaya...............>>>>>>', formData);
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
        message.error('Please fill in all required fields.');
      });
  };

  console.log('INFO=======', formData);
  const prev = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const GenderChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const handleFormChange = (changedValues, allValues) => {
    setFormData((prev) => ({ ...prev, allValues }));
    setInfo((pre) => ({ ...pre, ...allValues }));
    console.log('formdataaa========', allValues);
    console.log('formdataaa==changedValues======', changedValues);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (imageFile) {
      uploadImage();
    }
  }, [imageFile]);

  const uploadImage = async () => {
    try {
      if (!imageFile) {
        setImageUploadError(t('please-select-an-image'));
        return;
      }

      setImageUploadError(null);
      setImageFileUploading(true);
      setImageUploadProgress(20);

      const formData = new FormData();
      formData.append('file', imageFile);
      // formData.append('folderName', 'user');

      const URL = PATHS.IMAGE.POST;
      const data = await ApiUtils(
        URL,
        formData,
        METHOD_TYPES.POST,
        '',
        'multipart/form-data',
      );

      if (data.status === 200) {
        setImageUploadProgress(60);

        // Update formData with imageUrl
        const imageUrl = data.response[0].location;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));

        setImageFileUrl(imageUrl);
        setImageUploadProgress(null);
        setImageUploadError(null);
        setImageFileUploading(false);
      } else {
        throw new Error(t('image-upload-failed'));
      }
    } catch (error) {
      setImageFileUrl(null);
      setImageFileUploading(false);
      setImageUploadError(t('image-upload-failed'));
      setImageUploadProgress(null);
    }
  };

  const uploadResume = async (file) => {
    try {
      if (!file) {
        setResumeUploadError(t('please-select-a-resume'));
        return;
      }

      setResumeUploadError(null);
      setResumeUploading(true);
      setResumeUploadProgress(20);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('folderName', 'user');

      const URL = PATHS.IMAGE.POST;
      const data = await ApiUtils(
        URL,
        formData,
        METHOD_TYPES.POST,
        '',
        'multipart/form-data',
      );

      if (data.status === 200) {
        setResumeUploadProgress(60);

        const resumeUrl = data.response[0].location;
        setFormData((prevFormData) => ({
          ...prevFormData,
          resume: resumeUrl,
        }));

        setFileList([
          {
            uid: '-1',
            name: file.name,
            status: 'done',
            url: resumeUrl,
          },
        ]);

        setResumeUploadProgress(null);
        setResumeUploadError(null);
        setResumeUploading(false);
      } else {
        throw new Error(t('resume-upload-failed'));
      }
    } catch (error) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        resume: null,
      }));
      setResumeUploading(false);
      setResumeUploadError(t('resume-upload-failed'));
      setResumeUploadProgress(null);
    }
  };

  const validateMobileNumber = (_, value) => {
    if (value) {
      console.log('sdashdasdsadsadsadsad');
      if (value.length == 10) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Mobile number must be 10 digits'));
      }
    }
    console.log('123123123123123213213123');
  };

  const handleDepartmentChange = (value) => {
    const selectedDepartment = departmentId.find((dept) => dept._id === value);
    setFormData({
      ...formData,
      departmentId: value,
      manager_name: selectedDepartment ? selectedDepartment.lead_name : '',
    });
    form.setFieldsValue({
      ...formData,
      // departmentId: value,
      manager_name: selectedDepartment ? selectedDepartment.lead_name : '',
    });
  };

  console.log('DEPARTMENTTTTT>>>>>>>>>>>>', departmentId);

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };
  const steps = [
    {
      title: t('Personal Details'),
      content: (
        <>
          <div className="flex w-full flex-col  gap-4 mt-10">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={filePickerRef}
              style={{ display: 'none' }}
            />
            {imageFileUrl && (
              <button
                className="absolute top-2 right-2 bg-white p-1 rounded-full"
                onClick={() => {
                  setImageFile(null);
                  setImageFileUrl(null);
                }}
              >
                <AiFillDelete size={24} color="red" />
              </button>
            )}
            <div
              className="relative w-40 h-40 self-center cursor-pointer shadow-lg overflow-hidden rounded-full"
              onClick={() => filePickerRef.current.click()}
            >
              {imageFileUploadProgress && (
                <CircularProgressbar
                  value={imageFileUploadProgress || 0}
                  text={`${imageFileUploadProgress}%`}
                  strokeWidth={3}
                  styles={{
                    root: {
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    },
                    path: {
                      stroke: `rgba(62, 152, 199, ${
                        imageFileUploadProgress / 100
                      })`,
                    },
                  }}
                />
              )}

              <img
                src={
                  imageFileUrl
                    ? imageFileUrl
                    : formData?.imageUrl
                      ? formData?.imageUrl
                      : DefaultImage
                }
                alt="user"
                className={`rounded-full w-full h-full object-cover border-4 border-[green] ${
                  imageFileUploadProgress &&
                  imageFileUploadProgress < 100 &&
                  'opacity-60'
                }`}
              />
            </div>

            {imageFileUploadError && (
              <Message successMessage={imageFileUploadError} type={error} />
            )}
            {/* <Typography value={t('uploadImage')} />
            <div className="w-1/2 flex flex-col gap-2 mt-2">
              <ImageUpload
                formData={formData}
                setFormData={(image) =>
                  setFormData({ ...formData, imageUrl: image })
                }
                loading={(e) => setLoading(e)}
                t={t}
                type="single"
                listType="text"
              />
            </div> */}
          </div>
          <div className="flex flex-row  gap-4 sm:flex-column justify-between mt-10">
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('firstname')} />
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: t('First name is required') },
                  {
                    validator: (_, value) => {
                      if (/\d/.test(value)) {
                        return Promise.reject(
                          t('First name cannot contain numbers '),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <TextInput
                  type="text"
                  placeholder={t('Enterfirstname')}
                  id="_id"
                  className="flex-1"
                  pattern="[A-Za-z]*"
                  value={formData?.first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <Typography value={t('lastname')} />
              <Form.Item
                name="last_name"
                rules={[
                  { required: true, message: t('Last name is required') },
                  {
                    validator: (_, value) => {
                      if (/\d/.test(value)) {
                        return Promise.reject(
                          t('Last name cannot contain numbers '),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <TextInput
                  type="text"
                  placeholder={t('Enterlastname')}
                  required
                  id="last_name"
                  className="flex-1"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('Date of Birth')} />
              <Form.Item
                name="dob"
                rules={[
                  { required: true, message: t('Date of birth is required') },
                ]}
              >
                <DatePicker
                  placeholder="Select Date"
                  format="DD-MM-YYYY"
                  className="w-full h-10"
                  id="dob"
                  onChange={(value) => setFormData({ ...formData, dob: value })}
                  selectedValue={formData.dob ? dayjs(formData.dob) : null}
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3">
            <div className="w-full flex flex-col gap-2 ">
              <Typography value={t('Blood Group')} />
              <Form.Item
                name="blood_group"
                rules={[
                  { required: true, message: t('Blood group is required') },
                ]}
              >
                <Select
                  placeholder={t('Enter blood group')}
                  options={BLOOD_GROUP}
                  label="name"
                  className="flex-1 h-10"
                  id="value"
                  selectedValue={formData.blood_group}
                  onChange={(value) =>
                    setFormData({ ...formData, blood_group: value })
                  }
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <Typography
                value={t('Mobile Number')}
                isRequired={true}
                message=""
              />
              <Form.Item
                name="mobile_number"
                rules={[
                  {
                    required: true,
                    message: t(''),
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        if (
                          value?.length == 10 ||
                          value?.toString()?.length == 10
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            t('Mobile number must be 10 digits'),
                          );
                        }
                      } else {
                        return Promise.reject(t('Mobile number is required'));
                      }
                    },
                  },
                ]}
              >
                <NumberInput
                  type="number"
                  placeholder={t('Enter mobile number')}
                  id="_id"
                  defaultValue={formData?.mobile_number}
                  onChange={(e) =>
                    setFormData({ ...formData, mobile_number: e.target.value })
                  }
                  maxLength={10}
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('PerEmail')} />
              <Form.Item
                name="personal_email"
                rules={[
                  {
                    required: true,
                    message: `${formData.personal_email ? formData.personal_email : 'Email'} is required`,
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <TextInput
                  placeholder={t('Enter email')}
                  value={formData.personal_email}
                  onChange={(e) =>
                    setFormData({ ...formData, personal_email: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3">
            <div className="w-full flex flex-col gap-2 ">
              <Typography value={t('Gender')} />
              <Form.Item
                name="gender"
                rules={[{ required: true, message: t('Gender is required') }]}
              >
                <Select
                  placeholder={t('Select Gender')}
                  options={GENDER}
                  id="value"
                  className="flex-1 h-10"
                  label="name"
                  selectedValue={formData.gender}
                  onChange={(e) => setFormData({ ...formData, gender: e })}
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <Typography value={t('Marital Status')} />
              <Form.Item
                name="marital_status"
                rules={[
                  { required: true, message: t('Marital Status is required') },
                ]}
              >
                <Select
                  placeholder={t('Select marital status')}
                  className="flex-1 h-10"
                  id="name"
                  options={MARITAL_STATUS}
                  selectedValue={formData.marital_status}
                  onChange={(e) =>
                    setFormData({ ...formData, marital_status: e })
                  }
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <Typography value={t('Postal Code')} />
              <Form.Item
                name="zipcode"
                rules={[
                  { required: true, message: t('Postal code is required') },
                ]}
              >
                <NumberInput
                  type="number"
                  placeholder={t('Enter postal code')}
                  maxLength={6}
                  defaultValue={formData.personal_email}
                  onChange={(e) =>
                    setFormData({ ...formData, zipcode: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('addresS ')} />
            <Form.Item
              name="address"
              rules={[
                { required: true, message: t('Please provide your address') },
              ]}
            >
              <TextArea
                rows={4}
                placeholder={t('Enter address')}
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
              />
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <TitleAndBackComponent title={t('Edu Details')} />
            {eduFields.map((field1, index1) => (
              <div
                key={index1}
                className="flex flex-row gap-4 sm:flex-column justify-between mt-3 relative"
              >
                <div className="w-full flex flex-col gap-2">
                  {/* <Typography value={field1.label} /> */}
                  <Typography
                    value={t(
                      index1 == 0
                        ? 'SSLC'
                        : index1 == 1
                          ? 'HSC/Diploma'
                          : index1 == 2
                            ? 'Degeree'
                            : 'Other Qualification',
                    )}
                  />
                  <Form.Item
                    name={`education.edu_level_${index1}`}
                    // initialValue={field1.edu_level}
                    rules={[
                      {
                        required: true,
                        message: t('Qualification is required'),
                      },
                      {
                        validator: (_, value) => {
                          if (/\d/.test(value)) {
                            return Promise.reject(
                              t('Qualification cannot contain numbers'),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TextInput
                      placeholder={field1.placeholder}
                      value={field1.edu_level}
                      onChange={(e) => {
                        const newFields = [...eduFields];
                        newFields[index1].edu_level = e.target.value;
                        setEduFields(newFields);
                      }}
                      id={`edu_level_${index1}`}
                    />
                  </Form.Item>
                </div>

                <div className="w-full flex flex-col gap-2">
                  <Typography value={t('Institution')} />
                  <Form.Item
                    name={`education.org_name_${index1}`}
                    // initialValue={field1.org_name}
                    rules={[
                      {
                        required: true,
                        message: t('Institution name is required'),
                      },
                      {
                        validator: (_, value) => {
                          if (/\d/.test(value)) {
                            return Promise.reject(
                              t('Institution name cannot contain numbers'),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TextInput
                      placeholder={t('Institution Name')}
                      value={field1.org_name}
                      onChange={(e) => {
                        const newFields = [...eduFields];
                        newFields[index1].org_name = e.target.value;
                        setEduFields(newFields);
                      }}
                      maxLength={500}
                      id={`org_name_${index1}`}
                    />
                  </Form.Item>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <Typography value={t('Percentage')} />
                  <Form.Item
                    name={`education.percentage_${index1}`}
                    // initialValue={field1.percentage}
                    rules={[
                      {
                        required: true,
                        message: t('Percentage is required'),
                      },
                      {
                        pattern:
                          /^(?:100(?:\.0{1,2})?|[1-9]?\d(?:\.\d{1,2})?|0(?:\.\d{1,2})?)$/,
                        message: t('Please enter a valid percentage (0-100)'),
                      },
                    ]}
                  >
                    <NumberInput
                      type="text"
                      placeholder={t('Enter Percentage')}
                      value={field1.percentage}
                      onChange={(e) => {
                        const newFields = [...eduFields];
                        newFields[index1].percentage = e.target.value;
                        setEduFields(newFields);
                      }}
                      id="percentage"
                      maxLength={5}
                    />
                  </Form.Item>
                </div>
                {index1 >= 3 && (
                  <Button
                    type="danger"
                    onClick={() => handleRemoveEduField(index1)}
                    className="mt-8"
                    style={{
                      color: 'red',
                      background: 'transparent',
                      border: 'none',
                      boxShadow: 'none',
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      right: '-40px',
                    }}
                  >
                    <AiFillDelete style={{ fontSize: '20px' }} />
                  </Button>
                )}
              </div>
            ))}
          </div>

          <Button
            type="dashed"
            className="mb-10"
            style={{
              fontWeight: 600,
            }}
            onClick={handleAddEduField}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <BiAddToQueue style={{ fontSize: '20px', marginRight: '8px' }} />{' '}
              Add
            </span>
          </Button>
        </>
      ),
    },
    {
      title: t('Employment Details'),
      content: (
        <>
          <div className="w-full flex flex-col gap-2 mt-5">
            <TitleAndBackComponent title={t('Job Details')} />
            <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3">
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Employee ID')} />
                <Form.Item name="employeeId" rules={[{ required: false }]}>
                  <TextInput
                    placeholder={t('Enter employee ID')}
                    disabled={true}
                    // value={formData.employeeId}
                  />
                </Form.Item>
              </div>
              {roles.includes('admin') && (
                <div className="w-full flex flex-col gap-2">
                  <Typography value={t('Department')} />

                  <Form.Item
                    name="departmentId"
                    rules={[
                      {
                        required: true,
                        message: t('Department name is required'),
                      },
                    ]}
                  >
                    <Select
                      type="text"
                      placeholder={t('Select department')}
                      required
                      label="dept_name"
                      id="_id"
                      // disabled={true}
                      className="flex-1 h-10"
                      options={departmentId}
                      selectedValue={
                        formData.departmentId?._id || formData.departmentId
                      }
                      onChange={handleDepartmentChange}
                    />
                  </Form.Item>
                </div>
              )}
              {!roles.includes('admin') && (
                <div className="w-full flex flex-col gap-2">
                  <Typography value={t('Department')} />

                  <Form.Item
                    name="departmentId"
                    rules={[
                      {
                        required: true,
                        message: t('Department name is required'),
                      },
                    ]}
                  >
                    <Select
                      type="text"
                      placeholder={t('Select department')}
                      required
                      disabled={true}
                      label="dept_name"
                      id="_id"
                      // disabled={true}
                      className="flex-1 h-10"
                      options={departmentId}
                      selectedValue={
                        formData.departmentId?._id || formData.departmentId
                      }
                      onChange={handleDepartmentChange}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3">
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('Manager')} />

              <Form.Item
                name="manager_name"
                rules={[
                  { required: true, message: t('Manager name is required') },
                ]}
              >
                {/* <TextInput
                  className={'h-10'}
                  placeholder={t('Select Manager')}
                  label={'lead_name'}
                  id={'_id'}
                  value={formData.departmentId?.lead_name}
                  // onChange={(e) =>
                  //   setFormData({ ...formData, manager_name: e })
                  // }
                  disabled={true}
                /> */}
                <TextInput
                  placeholder={t('Manager name')}
                  disabled={true}
                  label={'lead_name'}
                  id={'_id'}
                  value={formData.departmentId?.manager_name}
                />
              </Form.Item>
            </div>
            {/* {console.log('fomr data-----', formData)} */}
            {roles.includes('admin') && (
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Designation')} />
                <Form.Item
                  name="designationId"
                  // initialValue={formData.designationId?._id || ''}
                  rules={[
                    {
                      required: true,
                      message: t('Designation is required'),
                    },
                    // {
                    //   validator: (_, value) => {
                    //     if (/\d/.test(value)) {
                    //       return Promise.reject(
                    //         t('Designation cannot contain numbers '),
                    //       );
                    //     }
                    //     return Promise.resolve();
                    //   },
                    // },
                  ]}
                >
                  <Select
                    // type="text"
                    placeholder={t('Select Designation')}
                    required
                    id="_id"
                    // disabled={true}
                    label="title"
                    options={designation}
                    className="flex-1 h-10"
                    selectedValue={
                      formData?.designationId?._id || formData?.designationId
                    }
                    onChange={function (value) {
                      // console.log('eeeeeeee======', value);
                      setFormData({ ...formData, designationId: value });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            {!roles.includes('admin') && (
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Designation')} />
                <Form.Item
                  name="designationId"
                  // initialValue={formData.designationId?._id || ''}
                  rules={[
                    {
                      required: true,
                      message: t('Designation is required'),
                    },
                    // {
                    //   validator: (_, value) => {
                    //     if (/\d/.test(value)) {
                    //       return Promise.reject(
                    //         t('Designation cannot contain numbers '),
                    //       );
                    //     }
                    //     return Promise.resolve();
                    //   },
                    // },
                  ]}
                >
                  <Select
                    // type="text"
                    placeholder={t('Select Designation')}
                    required
                    id="_id"
                    disabled={true}
                    label="title"
                    options={designation}
                    className="flex-1 h-10"
                    selectedValue={
                      formData?.designationId?._id || formData?.designationId
                    }
                    onChange={function (value) {
                      // console.log('eeeeeeee======', value);
                      setFormData({ ...formData, designationId: value });
                    }}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3 relative">
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('Email')} />
              <Form.Item
                name="email"
                initialValue={'email'}
                rules={[
                  {
                    required: false,
                    //   message: t('Please enter your Office email '),
                  },
                  // {
                  //   type: 'email',
                  //   message: t('Please enter a valid email address'),
                  // },
                ]}
              >
                <TextInput
                  type="text"
                  placeholder={t('Enter email')}
                  required
                  id="email"
                  disabled={true}
                  className="flex-1"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Item>
            </div>
            <div className="w-full flex flex-col gap-2">
              <Typography value={t('DOJ')} />

              <Form.Item
                name="doj"
                rules={[
                  { required: true, message: t('Date of joining is required') },
                ]}
              >
                <DatePicker
                  placeholder="Select Date"
                  format="DD-MM-YYYY"
                  className="w-full h-10"
                  id="doj"
                  onChange={(value) => setFormData({ ...formData, doj: value })}
                  selectedValue={formData?.doj ? dayjs(formData?.doj) : null}
                  defaultValue={dayjs()}
                />
              </Form.Item>
            </div>
          </div>

          <div className="w-full flex flex-col gap-2 mt-5">
            <TitleAndBackComponent title={t('Work Experience')} />
            <div className="mt-4">
              {expFields.map((field, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-4 sm:flex-column justify-between "
                >
                  <div className="w-full flex flex-col gap-2">
                    <Typography value={t('Designation')} isRequired={false} />
                    <Form.Item
                      name={`experience.role_name_${index}`}
                      // initialValue={field.role_name} // Ensure initialValue is set
                      rules={[
                        {
                          required: false,
                          // message: t('Designation is required'),
                        },
                        {
                          validator: (_, value) => {
                            if (/\d/.test(value)) {
                              return Promise.reject(
                                t('Designation cannot contain numbers '),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <TextInput
                        placeholder={t('Enter designation')}
                        value={field.role_name}
                        onChange={(e) => {
                          const expNewFields = [...expFields];
                          expNewFields[index].role_name = e.target.value;
                          setexpFields(expNewFields);
                        }}
                        maxLength={25}
                        id={`role_name_${index}`}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <Typography
                      value={t('Organization Name')}
                      isRequired={false}
                    />
                    <Form.Item
                      name={`experience.comp_name_${index}`}
                      // initialValue={field.comp_name}
                      rules={[
                        {
                          required: false,
                          // message: t('Organization name is required'),
                        },
                        {
                          validator: (_, value) => {
                            if (/\d/.test(value)) {
                              return Promise.reject(
                                t('Organization cannot contain numbers '),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <TextInput
                        placeholder={t('Enter organization name')}
                        value={field.comp_name}
                        onChange={(e) => {
                          const newFields = [...expFields];
                          newFields[index].comp_name = e.target.value;
                          setexpFields(newFields);
                        }}
                        maxLength={100}
                        id={`comp_name_${index}`}
                      />
                    </Form.Item>
                  </div>

                  <div className="w-full flex flex-col gap-2">
                    <Typography
                      value={t('Years of Experience')}
                      isRequired={false}
                    />
                    <Form.Item
                      name={`experience.yr_of_experience_${index}`}
                      // initialValue={field.yr_of_experience}
                      rules={[
                        {
                          required: false,
                        },
                        // { max: 2, message: t('Enter a valid data') },
                      ]}
                    >
                      <NumberInput
                        type="number"
                        placeholder={t('Enter years of experience')}
                        value={field.yr_of_experience}
                        onChange={(e) => {
                          const newFields = [...expFields];
                          newFields[index].yr_of_experience = e.target.value;
                          setexpFields(newFields);
                        }}
                        maxLength={2}
                        id="yr_of_experience"
                      />
                    </Form.Item>
                  </div>
                  {index >= 1 && (
                    <Button
                      type="danger"
                      onClick={() => handleRemoveField(index)}
                      className="mt-8"
                      style={{
                        color: 'red',
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        right: '-40px',
                      }}
                    >
                      <AiFillDelete style={{ fontSize: '20px' }} />
                    </Button>
                  )}
                </div>
              ))}
              <Button
                type="dashed"
                style={{
                  border: '1px dashed #75ff08',
                  background: 'transparent',
                  fontWeight: 600,
                  boxShadow: 'none',
                  color: 'rgb(20 177 1)',
                  hover: 'none',
                }}
                onClick={handleAddField}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BiAddToQueue
                    style={{ fontSize: '20px', marginRight: '8px' }}
                  />{' '}
                  Add
                </span>
              </Button>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 mt-10">
            <TitleAndBackComponent title={t('Emergency Contact info')} />
            <div className="flex flex-row gap-4 sm:flex-column justify-between mt-3">
              <div className="w-full flex flex-col gap-2 ">
                <Typography value={t('Emergency Contact name')} />
                <Form.Item
                  name="contact_name"
                  // initialValue={formData.emergency_details?.contact_name || ''}
                  rules={[
                    {
                      required: true,
                      message: t('Emergency contact name is required'),
                    },
                    {
                      validator: (_, value) => {
                        if (/\d/.test(value)) {
                          return Promise.reject(
                            t('Contact name cannot contain numbers '),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TextInput
                    placeholder={t('Contact name')}
                    value={formData.emergency_details?.contact_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        emergency_details: {
                          ...formData.emergency_details,
                          contact_name: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="w-full flex flex-col gap-2 ">
                <Typography value={t('Relationship to you')} />
                <Form.Item
                  name="relation"
                  rules={[
                    {
                      required: true,
                      message: t('Relationship to you is required'),
                    },
                    {
                      validator: (_, value) => {
                        if (/\d/.test(value)) {
                          return Promise.reject(
                            t('This field should not contain numbers'),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TextInput
                    placeholder={t('Enter relationship to you')}
                    value={formData.emergency_details?.relation}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        emergency_details: {
                          ...formData.emergency_details,
                          relation: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="w-full flex flex-col gap-2 ">
                <Typography value={t('Emergency Contact number')} />
                <Form.Item
                  name="mobilenumber"
                  rules={[
                    {
                      required: true,
                      message: t(''),
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          // console.log('sdashdasdsadsadsadsad', value);
                          if (
                            value?.length == 10 ||
                            value?.toString()?.length == 10
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              t('Mobile number must be 10 digits'),
                            );
                          }
                        } else {
                          return Promise.reject(
                            t('Emergency contact nuber is required'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <NumberInput
                    placeholder={t('Contact Number')}
                    type="number"
                    maxLength={10}
                    id="emergency_details.mobile_number"
                    value={formData.emergency_details?.mobile_number}
                    onChange={(e) => {
                      const mob_no = e.target.value
                        ? e.target.value.toString()
                        : '';
                      if (mob_no.length <= 10)
                        setFormData({
                          ...formData,
                          emergency_details: {
                            ...formData.emergency_details,
                            mobile_number: e.target.value,
                          },
                        });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="w-full flex flex-col gap-2 mt-3">
              <Typography value={t('Emergency Contact Address')} />
              <Form.Item
                name="contact_address"
                rules={[
                  {
                    required: true,
                    message: t('Emergency contact address is required'),
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder={t('Contact Address')}
                  value={formData.emergency_details?.contact_address}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      emergency_details: {
                        ...formData.emergency_details,
                        contact_address: e.target.value,
                      },
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
        </>
      ),
    },
    {
      title: t('Bank and KYC Details'),
      content: (
        <>
          <div className="w-full flex flex-col gap-2 mt-5">
            <TitleAndBackComponent title={t('Bank Details')} />
            <div className="flex flex-row gap-4 sm:flex-column justify-between">
              <div className="w-full flex flex-col gap-2 ">
                <Typography value={t('Account Number ')} />
                <Form.Item
                  // value="bank_details.account_no"
                  name={'account_no'}
                  rules={[
                    {
                      required: true,
                      message: t('Account number is required'),
                    },
                    {
                      max: 18,
                      message: t('Enter a valid account Number'),
                    },
                  ]}
                >
                  <NumberInput
                    type="number"
                    placeholder={t('Enter Account Number')}
                    required
                    id="bank_details.account_no"
                    className="flex-1"
                    maxLength={18}
                    value={formData.bank_details?.account_no}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bank_details: {
                          ...formData.bank_details,
                          account_no: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Bank Name')} />
                <Form.Item
                  name="bank_name"
                  // value="bank_details.bank_name"
                  rules={[
                    { required: true, message: t('Bank name is required') },
                    {
                      validator: (_, value) => {
                        if (/\d/.test(value)) {
                          return Promise.reject(
                            t('Bank name cannot contain numbers '),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder={t('Enter Bank Name')}
                    required
                    id="bank_name"
                    className="flex-1"
                    maxLength={30}
                    value={formData.bank_details?.bank_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bank_details: {
                          ...formData.bank_details,
                          bank_name: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-row gap-4 sm:flex-column justify-between">
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('IFSC Code')} />
                <Form.Item
                  // value="bank_details.ifsc"
                  name={'ifsc'}
                  rules={[
                    { required: true, message: t('IFSC code is required') },
                    { max: 11, message: t('Enter a valid IFSC code') },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder={t('Enter IFSC Code')}
                    required
                    id="ifsc"
                    className="flex-1"
                    maxLength={11}
                    value={formData.bank_details?.ifsc}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bank_details: {
                          ...formData.bank_details,
                          ifsc: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Branch Name')} />
                <Form.Item
                  // value="bank_details.branch"
                  name={'branch'}
                  rules={[
                    { required: true, message: t('Branch name is required') },
                    {
                      validator: (_, value) => {
                        if (/\d/.test(value)) {
                          return Promise.reject(
                            t('Branch name cannot contain numbers '),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder={t('Enter Branch Name')}
                    required
                    id="bank_details.branch"
                    className="flex-1"
                    maxLength={20}
                    value={formData.bank_details?.branch}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bank_details: {
                          ...formData.bank_details,
                          branch: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 mt-5">
            <TitleAndBackComponent title={t('KYC Details')} />
            <div className="flex flex-row gap-4 sm:flex-column justify-between">
              <div className="w-full flex flex-col gap-2 ">
                <Typography value={t('Pan Card')} />
                <Form.Item
                  name="pan_no"
                  rules={[
                    {
                      required: true,
                      message: t('Pan card number is required'),
                    },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder={t('Enter Pan Card')}
                    required
                    id="pan_no"
                    className="flex-1"
                    maxLength={10}
                    value={formData.pan_no}
                    onChange={(e) =>
                      setFormData({ ...formData, pan_no: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
              {/* {console.log('adhaarrr>>>>>>', formData.aadhar_no)} */}
              <div className="w-full flex flex-col gap-2">
                <Typography value={t('Aadhar')} />
                <Form.Item
                  name="aadhar_no"
                  rules={[
                    { required: true, message: t('Aadhar Number is required') },
                    { max: 12, message: t('Enter a valid aadhar number') },
                  ]}
                >
                  <NumberInput
                    type="number"
                    placeholder={t('Enter Aadhar Number')}
                    required
                    id="aadhar_no"
                    className="flex-1"
                    maxLength={12}
                    value={formData.aadhar_no}
                    onChange={(e) =>
                      setFormData({ ...formData, aadhar_no: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-row gap-4 sm:flex-column  justify-between ">
              {roles.includes('admin') && (
                <div className="w-1/2 flex flex-col gap-2 mt-4">
                  <Typography value={t('Status')} />
                  <Form.Item value="status">
                    <div className="flex flex-row gap-2">
                      <RadioGroup
                        onChange={handleRadioChange}
                        selectedValue={formData.status}
                        t={t}
                      />
                    </div>
                  </Form.Item>
                </div>
              )}
              <div className="w-1/2 flex flex-col gap-2 mt-3 self-center">
                <Typography value={t('Upload Resume')} isRequired={false} />
                <Form.Item value="resume">
                  <Upload.Dragger
                    name="file"
                    accept=".pdf"
                    fileList={fileList}
                    customRequest={({ file, onSuccess, onError }) => {
                      uploadResume(file)
                        .then(() => {
                          onSuccess(null, file);
                        })
                        .catch(onError);
                    }}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status !== 'uploading') {
                        console.log(info.file, info.fileList);
                      }
                      if (status === 'done') {
                        message.success(
                          `${info.file.name} file uploaded successfully.`,
                        );
                      } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                      }
                      setFileList(info.fileList);
                    }}
                    onDrop={(e) => {
                      console.log('Dropped files', e.dataTransfer.files);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <FaUpload />
                    </p>
                    <p className="ant-upload-text">
                      {t('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {t(
                        'Support for a single upload. Only PDF files are allowed.',
                      )}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  {
    /* <Upload.Dragger
                    name="file"
                    accept=".pdf"
                    beforeUpload={() => false}
                    fileList={formData.resume ? [formData.resume] : []}
                    onChange={(info) => {
                      const file = info.fileList[0];
                      setFormData({ ...formData, resume: file });
                      uploadResume(file);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <FaUpload />
                    </p>
                    <p className="ant-upload-text">
                      {t('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {t(
                        'Support for a single upload. Only PDF files are allowed.',
                      )}
                    </p>
                  </Upload.Dragger> */
  }
  return (
    <div className="p-3 max-w-5xl mx-auto min-h-screen ">
      <TitleAndBackComponent
        title={t('Update Profile')}
        goBackText={t('back')}
      />
      <Form
        className="flex flex-col gap-4 mt-5"
        onFinish={handleSubmit}
        form={form}
        onValuesChange={handleFormChange}
      >
        <Steps current={currentStep}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
        <div className="steps-action">
          <div className="flex flex-row sm:flex-column justify-between mt-6">
            {currentStep > 0 && (
              <Button
                onClick={() => prev()}
                style={{
                  backgroundColor: '#1890ff !important',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 14px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BiChevronsLeft
                  style={{ fontSize: '20px', marginRight: '8px' }}
                />
                {t('Previous')}
              </Button>
            )}

            {/* This empty flex item will push the "Next" button to the right */}
            <div className="flex-grow"></div>

            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                style={{
                  backgroundColor: '#1890ff !important',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 14px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('Next')}
                <BiChevronsRight
                  style={{ fontSize: '20px', marginLeft: '8px' }}
                />{' '}
              </Button>
            )}

            {currentStep === steps.length - 1 && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {})
                    .catch((errorInfo) => {
                      console.log('Validation failed:', errorInfo);
                      message.error('Please fill in all required fields.');
                    });
                }}
                loading={loading}
                disabled={loading}
              >
                {t('Save')}
              </Button>
            )}
          </div>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </Form>
    </div>
  );
}
