/* eslint-disable no-unused-vars */
import {
  Image,
  Table,
  Modal,
  Message,
  Button,
  FloatButton,
  Typography,
  Select,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import DefaultImage from '../../../assets/images/default-user.png';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../../routes/routes.json';
import {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';

const DashProjects = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  const navigate = useNavigate();

  const [projectId, setProject] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  // const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [usersId, setUserId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // const nameFilterValue = searchParams.get('name');
    const statusFilterValue = searchParams.get('status');
    if (statusFilterValue && statusFilterValue !== 'all') {
      let statusValue = parseInt(statusFilterValue);
      setSelectedStatus(statusValue);
    } else if (statusFilterValue === 'all') {
      setSelectedStatus([]);
    }
    // if (
    //   nameFilterValue &&
    //   nameFilterValue !== 'null' &&
    //   nameFilterValue !== 'undefined'
    // ) {
    //   setSearchText(nameFilterValue);
    // }

    if (roles?.includes('admin')) {
      fetchprojectList(searchParams);
    }
  }, [userId, location.search]);

  const fetchprojectList = async (searchParams) => {
    try {
      setLoading(true);
      // setTotalCount(null);
      let URL = `${PATHS.PROJECTASSIGN.GET}?${searchParams?.toString()}`;
      URL += `&limit=1000`;

      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setProjectList(data.response);
        setTotalCount(data.totalCount);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchprojectList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.PROJECT.GET + `?limit=1000`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);

        if (data.status === 200) {
          setProjectList(data.response);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (userId) {
      fetchprojectList();
    }
  }, [userId]);

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.USER.GET + `?limit=1000`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setUsersList(data.users);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (roles?.includes('admin')) {
      fetchUsersList();
    }
  }, [userId]);

  const handleUserChange = (selectedList) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedList) {
      setSelectedUsers(selectedList);
      setUserId(selectedList);
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: selectedList,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: '',
      });
    }
  };

  // const handleProjectChange = (selectedProject) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (selectedProject) {
  //     setSelectedProjects(selectedProject);
  //     setProject(selectedProject);
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       projectId: selectedProject,
  //     });
  //   } else {
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       projectId: '',
  //     });
  //   }
  // };

  const handleStatusFilterChange = (pagination, filters) => {
    console.log('pagination', pagination);
    console.log('pagination1', filters);
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.PROJECTASSIGN.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setProjectList((prev) => prev.filter((user) => user._id !== id));
        setShowCommonModal(false);
        setSuccessMessage(data.message);
      } else {
        console.log('error in del temple=====', data);
        setSuccessMessage(null);
        setShowCommonModal(false);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del temple1=====', error);
      setSuccessMessage(null);
      setShowCommonModal(false);
      setErrorMessage(t('error-pls-try-again-0'));
    }
  };

  const handleShowMore = async (page, pageSize) => {
    try {
      console.log('page', page, 'pageSize', pageSize);
      setPageCount(page);
      setPageSize(pageSize);
      setLoading(true);

      const searchParams = new URLSearchParams(location.search);
      let URL = `${PATHS.WORKLOG.GET}?${searchParams.toString()}&page=${page}&limit=${pageSize}&projectId=${projectId}`;
      URL += `&limit=${pageSize}&page=${page}`;
      if (roles.includes('admin')) {
        if (usersId) {
          URL += `&userId=${usersId}`;
        }
      }

      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setProjectList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: t('projectImage'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text, record) => (
        <Image
          width={50}
          src={record.projectId ? record.projectId?.imageUrl : DefaultImage} // Assuming DefaultImage is the default image component
          alt={record.name}
        />
      ),
    },
    {
      title: t('projectName'),
      dataIndex: ['projectId', 'name'],
      key: 'projectName',
    },
    {
      title: t('assignname'),
      dataIndex: ['userId', 'first_name'],
      key: 'projectName',
    },

    getStatusColumn(t, selectedStatus),
    getEditColumn(t, routesnkt.updateprojectAssign),
    getDeleteColumn(t, handleDeleteUser),
  ];

  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />

      <div className="flex flex-col md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
        <div className="w-full md:w-1/3 flex flex-col gap-2">
          <Typography value={t('selectUser')} />
          <Select
            options={usersList}
            className={'w-full'}
            value={selectedUsers}
            onChange={handleUserChange}
            placeholder={t('selectaUser')}
          />
        </div>

        {/* <div className="w-full md:w-1/3 flex flex-col gap-2">
          <Typography value={t('selectProject')} />
          <Select
            value={selectedProjects}
            onChange={handleProjectChange}
            options={projectList}
            placeholder={t('select')}
            label="projectId.name"
            id="projectId._id"
          />
        </div> */}

        <div className="w-full md:w-1/4 flex flex-col gap-2">
          <Link to={routesnkt.createProjectAssign}>
            <Button
              type="button"
              gradientDuoTone="purpleToBlue"
              className="w-full"
            >
              {t('assign-proj')}
            </Button>
          </Link>
        </div>
      </div>

      <>
        {roles?.includes('admin') && (
          <Table
            dataSource={projectList}
            columns={columns}
            onChange={handleStatusFilterChange}
            pagination={{
              showSizeChanger: true,
              pageSize: pageSize,
              total: totalCount,
              current: pageCount,
              onChange: (page, pageSize) => {
                handleShowMore(page, pageSize);
              },
            }}
            loading={loading}
          />
        )}
        <Modal
          title={t('confirmation')}
          visible={showCommonModal}
          onOk={handleDeleteUser}
          onCancel={() => setShowCommonModal(false)}
          okText={t('yes-i-and-apos-m-sure-0')}
          cancelText={t('no-cancel-0')}
        >
          {t('are-you-sure-you-want-to-delete-this')}
        </Modal>
      </>
      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};

export default DashProjects;
