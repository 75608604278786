import { useState, useEffect } from 'react';
import {
  RadioGroup,
  Button,
  Typography,
  Select,
  Message,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import routesnkt from '../../routes/routes.json';

export default function CreateModuels() {
  const { theme } = useSelector((state) => state.theme);
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchCatList = async () => {
        const URL = PATHS.SUBPROJECT.GET + '?status=1&limit=100';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setProjectList(data.response);
        }
      };

      fetchCatList();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchCatList = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      };

      fetchCatList();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    // e.preventDefault();
    try {
      if (!formData.projectId) {
        return setPublishError(t('select-a-project'));
      }
      if (!formData.userId) {
        return setPublishError(t('selectaUser'));
      }
      setLoading(true);
      if (roles.includes('admin')) {
        const URL = PATHS.PROJECTASSIGN.ADD;
        const data = await ApiUtils(URL, formData, METHOD_TYPES.POST);
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.messsage);
          setPublishError(null);
          navigate(routesnkt.projectsAllocate);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('err in create projectassign', error);
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('addassign-proj')}
        goBackText={t('back')}
      />
      <Form onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 sm:flex-column justify-between mt-4">
          <div className=" w-full flex flex-col gap-2">
            <Typography value={t('selectaEmp')} />
            <Form.Item rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(e) => setFormData({ ...formData, userId: e })}
                options={userList}
                placeholder={t('selectaUser')}
                showSearch={true}
                theme={theme}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectProject')} />
            <Form.Item rules={[{ required: true }]}>
              <Select
                required={true}
                className={'h-10'}
                onChange={(e) => setFormData({ ...formData, projectId: e })}
                options={projectList}
                placeholder={t('selectaProject')}
                label={'name'}
                showSearch={true}
                mode={'multiple'}
                theme={theme}
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('select-status')} />
          <Form.Item rules={[{ required: true }]}>
            <div className="flex flex-row gap-2">
              <RadioGroup
                onChange={handleRadioChange}
                selectedValue={formData.status}
                t={t}
              />
            </div>
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('assign-proj')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
