import React, { FC } from 'react';
import { Radio } from 'antd';

interface RadioOption {
  label: string;
  value: number | string;
}

interface CustomRadioGroupProps {
  selectedValue?: number | string;
  onChange: (e: any) => void;
  t: (key: string) => string;
  options?: RadioOption[];
}

export const CustomRadioGroup: FC<CustomRadioGroupProps> = ({
  selectedValue = 1,
  onChange,
  t,
  options,
}) => {
  if (!options) {
    options = [
      { label: t('active'), value: 1 },
      { label: t('inActive'), value: 2 },
    ];
  }

  return (
    <Radio.Group onChange={onChange} value={selectedValue}>
      {options.map((option) => (
        <Radio key={option.value} value={option.value}>
          {t(option.label)}
        </Radio>
      ))}
    </Radio.Group>
  );
};
