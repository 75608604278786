import {
  Spinner,
  TextInput,
  Button,
  Typography,
  Message,
} from '../../utils/antd-components/index.tsx';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInStart,
  signInStop,
  signInFailure,
} from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.jsx';
import { t } from 'i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';

export default function SignIn() {
  const authUser = useAuthUser();
  const userEmail = authUser.email;
  const [formData, setFormData] = useState({});
  const { loading, error: errorMessage } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.code) {
      return dispatch(signInFailure(t('please-fill-all-the-fields')));
    }
    if (!userEmail) {
      return dispatch(signInFailure(t('email-not-found')));
    }
    try {
      dispatch(signInStart());
      let payload = {
        ...formData,
        email: userEmail,
      };
      const URL = PATHS.AUTH.VERIFYEMAIL;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (data.status !== 200) {
        dispatch(signInFailure(data.message));
      }

      if (data.status === 200) {
        navigate('/sign-in');
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };
  const resendCode = async () => {
    try {
      dispatch(signInStart());
      let payload = {
        email: userEmail,
      };
      const URL = PATHS.AUTH.RECENDCODE;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (data.status !== 200) {
        dispatch(signInFailure(data.message));
      }
      if (data.status === 200) {
        dispatch(signInStop());
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };
  return (
    <div className="min-h-screen mt-20">
      <div className="flex p-3 max-w-3xl mx-auto flex-col md:flex-row md:items-center gap-5">
        {/* left */}
        {/* <div className='flex-1'>
                    <Link to='/' className='font-bold dark:text-white text-4xl'>
                        <span className='px-2 py-1 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-lg text-white'>
                            Sahands
                        </span>
                        Blog
                    </Link>
                    <p className='text-sm mt-5'>
                        This is a demo project. You can sign in with your email and password
                        or with Google.
                    </p>
                </div> */}
        {/* right */}

        <div className="flex-1">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <Typography value={t('your-code')} />
              <TextInput
                type="code"
                placeholder="**********"
                id="code"
                onChange={handleChange}
              />
            </div>
            <Button
              gradientDuoTone="purpleToBlue"
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner size="large" />
                  <span className="pl-3">{t('loading...')}</span>
                </>
              ) : (
                t('Verify')
              )}
            </Button>
          </form>
          <div className="flex gap-2 text-sm mt-3">
            <span>{t('resend-code')}</span>
            <p onClick={resendCode} className="underline cursor-pointer">
              {t('Resend')}
            </p>
          </div>
          {errorMessage && (
            <Message className="mt-5" type="error" message={errorMessage} />
          )}
        </div>
      </div>
    </div>
  );
}
