import {
  Image,
  Table,
  Button,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import DefaultImage from '../../../assets/images/default-user.png';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import SearchableColumn from '../../../utils/reUsable/SearchableColumn.jsx';
import routesnkt from '../../../routes/routes.json';
import {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';

export default function DashUsers() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  // const userId = authUser._id;
  const roles = authUser.rolesType;
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameFilterValue = searchParams.get('name');
    const statusFilterValue = searchParams.get('status');
    if (statusFilterValue && statusFilterValue !== 'all') {
      let statusValue = parseInt(statusFilterValue);
      setSelectedStatus(statusValue);
    } else if (statusFilterValue === 'all') {
      setSelectedStatus([]);
    }
    if (
      nameFilterValue &&
      nameFilterValue !== 'null' &&
      nameFilterValue !== 'undefined'
    ) {
      setSearchText(nameFilterValue);
    }

    if (roles?.includes('admin')) {
      fetchUsersList(searchParams);
    }
  }, [location.search]);

  const fetchUsersList = async (searchParams) => {
    try {
      setLoading(true);
      const URL = `${PATHS.USER.GET}?${searchParams?.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.users);
        setTotalCount(data.totalCount);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    // console.log('pagination', pagination);
    // console.log('pagination1', filters);
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.USER.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setUsersList((prev) => prev.filter((user) => user._id !== id));
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      ...SearchableColumn({ dataIndex: 'username', searchText }),
      filteredValue: null,
      render: function (text, record) {
        // console.log(record.first_name, record.last_name);
        return (
          <div className="flex items-center space-x-4">
            <div className="avatar rounded-full overflow-hidden w-12 h-12">
              <Image
                className="aspect-square w-full h-full object-cover"
                width={48}
                height={48} // Make sure to specify both width and height for the Image component
                src={record.imageUrl ? record.imageUrl : DefaultImage}
                alt={record.username}
              />
            </div>
            <span className="items-center">
              {record.first_name} {record.last_name}
            </span>
          </div>
        );
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      filteredValue: null,
    },
    {
      title: t('roles'),
      dataIndex: ['roleId', 'type'],
      key: 'roles',
      filteredValue: null,
    },
    {
      title: t('Designation'),
      dataIndex: ['designationId', 'title'],
      key: 'title',
      filteredValue: null,
    },
    getStatusColumn(t, selectedStatus),
    getEditColumn(t, routesnkt.updateuser),
    getDeleteColumn(t, handleDeleteUser),
  ];

  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2">
        <Link to={routesnkt.createUser}>
          <Button type="button" gradientDuoTone="purpleToBlue">
            {t('addUser')}
          </Button>
        </Link>
      </div>
      <>
        {roles?.includes('admin') && (
          <Table
            dataSource={usersList}
            columns={columns}
            onChange={handleStatusFilterChange}
            pagination={{
              current: pageCount,
              pageSize: 10,
              total: totalCount,
            }}
            loading={loading}
          />
        )}
      </>
    </div>
  );
}
