import {
  Table,
  FloatButton,
  Button,
  Typography,
  Select,
  Message,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import {
  getSessionColumn,
  getEditColumn,
  getDeleteColumn,
  //getLeaveTypeColumn,
} from '../../../utils/reUsable/commonColumn.jsx';
import routesnkt from '../../../routes/routes.json';
//import parser from 'html-react-parser';
import { AiOutlineExport } from 'react-icons/ai';
import * as XLSX from 'xlsx';

export default function DashAbsentList() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const roles = authUser.rolesType;
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const [absList, setAbsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  // const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // const LeaveTypeFilterValue = searchParams.get('type');
    const sessionFilterValue = searchParams.get('session');

    if (sessionFilterValue && sessionFilterValue !== 'all') {
      let sessionValue = parseInt(sessionFilterValue);
      setSelectedSession(sessionValue);
    } else if (sessionFilterValue === 'all') {
      setSelectedSession([]);
    }
    // if (LeaveTypeFilterValue && LeaveTypeFilterValue !== 'all') {
    //   let leaveTypeValue = parseInt(LeaveTypeFilterValue);
    //   setSelectedLeaveType(leaveTypeValue);
    // } else if (LeaveTypeFilterValue === 'all') {
    //   setSelectedLeaveType([]);
    // }

    if (roles?.includes('admin')) {
      fetchAttendanceList(searchParams);
    }
  }, [location.search]);

  const fetchAttendanceList = async (searchParams) => {
    try {
      setLoading(true);
      const URL = `${PATHS.ATTENDANCE.GET}?${searchParams?.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);

      if (data.status === 200) {
        // Filter for records where leaveType is only 5
        const filteredResponse = data.filteredResponse.filter(
          (item) => item.type === 5,
        );

        console.log('Filtered abs list (leaveType 5 only)', filteredResponse);
        setAbsList(filteredResponse);
        setTotalCount(filteredResponse.length); // Set the count based on filtered data
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.USER.GET + `?limit=1000`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          console.log('abset list++', data.users);
          setUsersList(data.users);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (roles?.includes('admin')) {
      fetchUsersList();
    }
  }, [userId, roles]);

  const handleStatusFilterChange = (pagination, filters) => {
    const sessionFilterValue = filters.session && filters.session[0];
    const leaveTypeFilterValue = filters.type && filters.type[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);

    const newParams = {
      ...Object.fromEntries(searchParams),
      session: sessionFilterValue || 'all',
      type: leaveTypeFilterValue || 'all',
      limit: pagination.pageSize,
      page: pagination.current,
    };

    updateURL(newParams);
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleDeleteAttend = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.ATTENDANCE.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        fetchAttendanceList(new URLSearchParams(location.search));
        setSuccessMessage('Attendance Deleted Successfully');
      } else {
        setErrorMessage('Failed to delete attendance record');
        console.log(data.message);
      }
    } catch (error) {
      setErrorMessage('An error occurred while deleting the record');
      console.log(error.message);
    }
  };

  const handleUserChange = (selectedList) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedList) {
      setSelectedUsers(selectedList);
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: selectedList,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: '',
      });
    }
  };

  const columns = [
    {
      title: t('date'),
      key: 'date',
      render: (_, record) => {
        if (record.startDate && record.endDate) {
          const startDate = new Date(record.startDate).toLocaleDateString(
            'en-GB',
            {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            },
          );
          const endDate = new Date(record.endDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
          return `${startDate} - ${endDate}`;
        } else if (record.date) {
          return new Date(record.date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        } else {
          return 'N/A'; // Default if no date fields are available
        }
      },
      sorter: (a, b) => {
        const aDate = a.startDate || a.date;
        const bDate = b.startDate || b.date;
        return new Date(aDate) - new Date(bDate);
      },
    },

    {
      title: t('userName'),
      dataIndex: ['userId', 'first_name'],
      key: 'userId',
      // Uncomment and adjust sorting logic if needed
      // sorter: (a, b) => {
      //   const nameA = a.first_name || '';
      //   const nameB = b.first_name || '';
      //   return nameA.localeCompare(nameB);
      // },
    },
    getSessionColumn(t, selectedSession),
    {
      title: t('selectduration'),
      dataIndex: 'time',
      key: 'time',
    },
    // getLeaveTypeColumn(t, selectedLeaveType),
    // {
    //   title: t('Reason'),
    //   dataIndex: 'reason',
    //   key: 'reason',
    //   render: (reason) => parser(reason),
    // },
    getEditColumn(t, routesnkt.updateWfoAttendance),
    getDeleteColumn(t, handleDeleteAttend),
  ];

  const handleExport = () => {
    const data = absList.map((item) => ({
      Date: new Date(item.date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }),
      UserName: item.userId.first_name,
      Session: item.session,
      LeaveType: item.leaveType,
      Reason: item.reason.replace(/<[^>]*>?/gm, ''),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Absences');

    XLSX.writeFile(workbook, 'Permissions_List.xlsx');
  };

  return (
    <div className="lg:w-full table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <>
        <div className="flex flex-col w-full md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
          <div className="w-full md:w-1/4 flex flex-col gap-2">
            <Typography value={t('selectUser')} />
            <Select
              options={usersList}
              className={'w-full'}
              value={selectedUsers}
              onChange={handleUserChange}
              placeholder={t('selectaUser')}
            />
          </div>
          <div className=" flex flex-row gap-2 item-end">
            <div className=" flex">
              <Link to={routesnkt.createWfoAttendance}>
                <Button type="button" gradientDuoTone="purpleToBlue">
                  {t('Mark Attendance')}
                </Button>
              </Link>
            </div>
            <div className=" flex flex-col  gap-2 justify-center item-center">
              <Button
                type="primary"
                className="items-center justify-center"
                icon={<AiOutlineExport />}
                onClick={handleExport}
              >
                {t('Export')}
              </Button>
            </div>
          </div>
        </div>

        {roles?.includes('admin') && (
          <Table
            dataSource={absList}
            columns={columns}
            onChange={handleStatusFilterChange}
            pagination={{
              current: pageCount,
              pageSize: 10,
              total: totalCount,
            }}
            loading={loading}
          />
        )}
        {errorMessage && <Message successMessage={errorMessage} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </>
    </div>
  );
}
