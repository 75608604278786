const PATHS = Object.freeze({
  AUTH: {
    LOGIN: '/nkt/auth/login',
    CHANGEPASS: '/nkt/auth/changepassword',
    VERIFYEMAIL: '/nkt/auth/verify-email',
    RECENDCODE: '/nkt/auth/resend-code',
  },
  USER: {
    GET: '/adminnkt/user/get-user',
    GET_U: '/nkt/user/get-user',
    POST: '/adminnkt/user/create-user',
    UPDATE: '/nkt/user/update-user',
    AUTH: 'api/v1/cognito/login',
    ADD: '/adminnkt/images/add-images/',
    DELETE: '/nkt/user/delete-user',
    CLOCKIN: '/nkt/user/clock-in',
    CLOCKOUT: '/nkt/user/clock-out',
    USERTIMINGS: '/nkt/user/get-timings',
    USERTIMINGSUPDATE: '/adminnkt/user/update-timings',
    USERTIMINGSDELETE: '/adminnkt/user/delete-timings',
  },
  PROJECT: {
    ADD: '/adminnkt/projects/add-projects/',
    GET: '/adminnkt/projects/get-projects',
    DELETE: '/adminnkt/projects/delete-projects',
    UPDATE: '/adminnkt/projects/update-projects',
    INDIVIDUAL: '/nkt/modules/get-projects',
  },
  SUBPROJECT: {
    ADD: '/adminnkt/subprojects/add-subprojects',
    GET: '/adminnkt/subprojects/get-subprojects',
    DELETE: '/adminnkt/subprojects/delete-subprojects',
    UPDATE: '/adminnkt/subprojects/update-subprojects',
    INDIVIDUAL: '/nkt/subprojects/get-subprojects',
  },
  ROLES: {
    ADD: '/adminnkt/roles/add-roles/',
    GET: '/adminnkt/roles/get-roles',
    // GET_U: '/nkt/roles/get-roles',
    DELETE: '/adminnkt/roles/delete-roles',
    UPDATE: '/adminnkt/roles/update-roles',
  },
  IMAGE: {
    POST: '/nkt/images/add-images',
    DELETE: '/adminnkt/images/delete-images',
    GET: '/adminnkt/images/get-images',
  },
  MODULES: {
    ADD: '/nkt/modules/add-modules',
    GET: '/nkt/modules/get-modules',
    DELETE: '/nkt/modules/delete-modules',
    UPDATE: '/nkt/modules/update-modules',
    PROJECT: '/nkt/modules/get-projects',
  },
  WORKLOG: {
    ADD: '/nkt/worklog/add-worklog',
    GET: '/nkt/worklog/get-worklog',
    DELETE: '/nkt/worklog/delete-worklog',
    UPDATE: '/nkt/worklog/update-worklog',
    PROJECT: '/nkt/worklog/get-projects',
  },
  PROJECTASSIGN: {
    ADD: '/adminnkt/projectsassign/add-projectsassign',
    UPDATE: '/adminnkt/projectsassign/update-projectsassign',
    GET: '/adminnkt/projectsassign/get-projectsassign',
    GETUSERS: '/nkt/projectsassign/get-projectsassign',
    DELETE: '/adminnkt/projectsassign/delete-projectsassign',
  },
  CONFIG: {
    ADD: '/adminnkt/config/add-config',
    GET: '/adminnkt/config/get-config',
    DELETE: '/adminnkt/config/delete-config',
    UPDATE: '/adminnkt/config/update-config',
  },
  HOLIDAY: {
    ADD: '/adminnkt/holiday/add-holiday',
    GET: '/nkt/holidays/get-holiday',
    DELETE: '/adminnkt/holiday/delete-holiday',
    UPDATE: '/adminnkt/holiday/update-holiday',
  },
  ATTENDANCE: {
    ADD: '/adminnkt/attendance/add-attendance',
    GET: '/adminnkt/attendance/get-attendance',
    UPDATE: '/adminnkt/attendance/update-attendance',
    DELETE: '/adminnkt/attendance/delete-attendance',
  },
  DEPARTMENT: {
    ADD: '/adminnkt/department/add-department',
    GET: '/adminnkt/department/get-department',
    GET_U: '/nkt/department/get-department',
    UPDATE: '/adminnkt/department/update-department',
    DELETE: '/adminnkt/department/delete-department',
  },
  DESIGINATION: {
    ADD: '/adminnkt/designation/add-designation',
    UPDATE: '/adminnkt/designation/update-designation/',
    GET: '/adminnkt/designation/get-designation',
    GET_U: '/nkt/designation/get-designation',
    DELETE: '/adminnkt/designation/delete-designation',
  },
});
export default PATHS;
