import {
  Table,
  Message,
  // Select,
  Button,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
// import { STATUSLIST } from '../../../utils/constants/index.js';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import routesnkt from '../../../routes/routes.json';
import parser from 'html-react-parser';
import {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';

const DashConfig = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  // const translatedSTATUSLIST = STATUSLIST(t);
  const [configList, setConfigList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    const fetchHolidaysList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.HOLIDAY.GET + `?status=all`;
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        if (data.status === 200) {
          setConfigList(data.response);
          setTotalCount(data.totalCount);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('err in dash config', error.message);
      }
    };
    if (roles?.includes('admin')) {
      fetchHolidaysList();
    }
  }, [userId, filter]);
  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    if (statusFilterValue) {
      setSelectedStatus(statusFilterValue);
      setFilter(statusFilterValue);
    }
  };

  const handleShowMore = async (page) => {
    try {
      setPageCount(page);
      setLoading(true);
      setPageCount(page);
      const URL = PATHS.HOLIDAY.GET + `?limit=10&status=${filter}&page=${page}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setConfigList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('dash in paginatin', error.message);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.HOLIDAY.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setConfigList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        console.log('error in del temple=====', data);
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del temple1=====', error);
      setSuccessMessage(null);
      setErrorMessage(t('error-pls-try-again-0'));
    }
  };

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) =>
        new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t('Reason'),
      dataIndex: 'content',
      key: 'content',
      render: (content) => parser(content),
    },
    getStatusColumn(t, selectedStatus),
    getEditColumn(t, routesnkt.updateholiday),
    getDeleteColumn(t, handleDeleteUser),
  ];

  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2 mt-5">
        {/* <Select
          required={true}
          onChange={(e) => setFilter(e)}
          options={translatedSTATUSLIST}
          placeholder={t('select')}
          label="name"
          className={'w-1/4'}
        /> */}
        <Link to={routesnkt.createHoliday}>
          <Button
            type="button"
            gradientDuoTone="purpleToBlue"
            className="w-full"
          >
            {t('Createholiday')}
          </Button>
        </Link>
      </div>
      <>
        {roles?.includes('admin') && (
          <Table
            dataSource={configList}
            columns={columns}
            onChange={handleStatusFilterChange}
            pagination={{
              pageSize: 10,
              total: totalCount,
              current: pageCount,
              onChange: (page, pageSize) => {
                handleShowMore(page, pageSize);
              },
            }}
            loading={loading}
          />
        )}
      </>
      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default DashConfig;
