import { FC } from 'react';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker'; // For typing DatePickerProps which includes all underlying props

interface CustomDatePickerProps
  extends Omit<DatePickerProps, 'onChange' | 'value'> {
  selectedValue?: DatePickerProps['value'];
  onChange?: DatePickerProps['onChange'];
  readOnly?: boolean;
}

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  selectedValue,
  onChange,
  format = 'HH:mm a',
  placeholder = 'Select Date',
  disabled,
  className,
  readOnly = true,
  defaultValue,
  disabledDate,
  picker,
}) => {
  return (
    <DatePicker
      className={className}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      disabled={disabled}
      defaultValue={defaultValue}
      readOnly={readOnly}
      disabledDate={disabledDate}
      picker={picker}
    />
  );
};
