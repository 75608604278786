// BREADCRUMBPATHS.js

export const BREADCRUMBPATHS = (t: any) => ({
  'create-user': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('employees')} href={'/users'}>
          {t('employees')}
        </a>
      ),
    },
    { title: t('createaUser') },
  ],
  'create-worklogs': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('work-log')} href={'/work-log-report'}>
          {t('work-log')}
        </a>
      ),
    },
    { title: t('add_worklog') },
  ],
  'create-modules': [
    { title: t('home'), link: '/' },

    {
      title: (
        <a key={t('moduleORactivity')} href={'/modules'}>
          {t('moduleORactivity')}
        </a>
      ),
    },
    { title: t('addModuleORActivity') },
  ],
  'create-projects-assign': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('assign-proj')} href={'/projects-allocate'}>
          {t('assign-proj')}
        </a>
      ),
    },
    { title: t('addassign-proj') },
  ],
  'create-projects': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('projects')} href={'/projects'}>
          {t('projects')}
        </a>
      ),
    },
    { title: t('createProject') },
  ],
  'mark-absent': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('Attendance')} href={'/attendance'}>
          {t('Attendance')}
        </a>
      ),
    },
    { title: t('Mark Absent') },
  ],
  'create-roles': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('roles')} href={'/roles'}>
          {t('roles')}
        </a>
      ),
    },
    { title: t('createRole') },
  ],
  'create-department': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('department')} href={'/department'}>
          {t('department')}
        </a>
      ),
    },
    { title: t('CreateDepartment') },
  ],
  'create-holiday': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('roles')} href={'/holiday-list'}>
          {t('roles')}
        </a>
      ),
    },
    { title: t('Create Holidays') },
  ],
  'update-holiday': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('roles')} href={'/holiday-list'}>
          {t('roles')}
        </a>
      ),
    },
    { title: t('Update Holidays') },
  ],
  'update-modules': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('moduleORactivity')} href={'/modules'}>
          {t('moduleORactivity')}
        </a>
      ),
    },
    { title: t('updateModules') },
  ],
  'update-projects-assign': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('assign-proj')} href={'/projects-allocate'}>
          {t('assign-proj')}
        </a>
      ),
    },
    { title: t('updateProjectsAssign') },
  ],
  'update-projects': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('projects')} href={'/projects'}>
          {t('projects')}
        </a>
      ),
    },
    { title: t('updateProjects') },
  ],
  'update-absentees': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('absentList')} href={'/absent-list'}>
          {t('absentList')}
        </a>
      ),
    },
    { title: t('Update Attendance') },
  ],
  'update-roles': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('roles')} href={'/roles'}>
          {t('roles')}
        </a>
      ),
    },
    { title: t('updateRoles') },
  ],
  'update-department': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('department')} href={'/department'}>
          {t('department')}
        </a>
      ),
    },
    { title: t('UpdateDepartment') },
  ],

  'update-users': [
    { title: t('home'), link: '/' },
    // {
    //   title: (
    //     <a key={t('employees')} href={'/users'}>
    //       {t('employees')}
    //     </a>
    //   ),
    // },
    { title: t('Update Profile') },
  ],

  'create-sub-projects': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('SubProject')} href={'/sub-projects'}>
          {t('SubProject')}
        </a>
      ),
    },
    { title: t('addSubProject') },
  ],
  'update-sub-projects': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('SubProject')} href={'/sub-projects'}>
          {t('SubProject')}
        </a>
      ),
    },
    { title: t('Update Sub Projects') },
  ],
  'update-user-timings': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('clock-in')} href={'/clock-ins'}>
          {t('clock-in')}
        </a>
      ),
    },
    { title: t('UpdateUserTimings') },
  ],
  dashboard: [{ title: t('home'), link: '/' }, { title: t('dashboard') }],
  modules: [{ title: t('home'), link: '/' }, { title: t('moduleORactivity') }],
  profile: [{ title: t('home'), link: '/' }, { title: t('profile') }],
  'projects-allocate': [
    { title: t('home'), link: '/' },
    { title: t('assign-proj') },
  ],
  projects: [{ title: t('home'), link: '/' }, { title: t('projects') }],
  department: [{ title: t('home'), link: '/' }, { title: t('department') }],
  config: [{ title: t('home'), link: '/' }, { title: t('config') }],
  roles: [{ title: t('home'), link: '/' }, { title: t('roles') }],
  settings: [{ title: t('home'), link: '/' }, { title: t('settings') }],
  users: [{ title: t('home'), link: '/' }, { title: t('employees') }],
  'clock-ins': [{ title: t('home'), link: '/' }, { title: t('clock-ins') }],
  'work-log-report': [
    { title: t('home'), link: '/' },
    { title: t('work-log') },
  ],
  'sub-projects': [{ title: t('home'), link: '/' }, { title: t('SubProject') }],
  attendance: [{ title: t('home'), link: '/' }, { title: t('Attendance') }],
  'absent-list': [{ title: t('home'), link: '/' }, { title: t('absentList') }],
  'holiday-list': [
    { title: t('home'), link: '/' },
    { title: t('holidaylist') },
  ],
  'attendance-report': [
    { title: t('home'), link: '/' },
    {
      title: (
        <a key={t('Attendance')} href={'/attendance'}>
          {t('Attendance')}
        </a>
      ),
    },
    { title: t('Attendance Report') },
  ],
});
