import {
  Message,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Form } from 'antd';
import routesnkt from '../../routes/routes.json';

const UpdateRole = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    // type: '',
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const { postId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchPost = async () => {
        const URL = PATHS.ROLES.GET;
        const data = await ApiUtils(URL + `?_id=${postId}&status=all`);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.response[0]);
        }
      };

      fetchPost();
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);
    try {
      if (roles.includes('admin')) {
        const URL = `${PATHS.ROLES.UPDATE}/${formData._id}`;
        const data = await ApiUtils(URL, formData, METHOD_TYPES.PUT);
        setLoading(true);
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.roles);
        }
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  const handleTextChange = (e) => {
    setFormData({ ...formData, type: e.target.value });
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent title={t('updateRoles')} goBackText={t('back')} />
      <Form className="flex flex-col gap-4" onFinish={handleSubmit}>
        <div className="flex flex-col gap-2 sm:flex-col justify-between">
          <Typography value={t('createRoles')} />
          <Form.Item value="type" rules={[{ required: false }]}>
            <TextInput
              type="text"
              placeholder={t('createRoles')}
              required
              id="name"
              className="flex-1"
              onChange={handleTextChange}
              value={formData?.type}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <Typography value={t('selectactive')} />
          <Form.Item value="status">
            <div className="flex flex-row gap-2">
              <RadioGroup
                onChange={handleRadioChange}
                selectedValue={formData.status}
                t={t}
              />
            </div>
          </Form.Item>
        </div>
        <div className="flex justify-end w-full">
          <Button
            type="primary"
            htmlType="submit"
            gradientDuoTone="purpleToBlue"
            loading={loading}
            disabled={loading}
          >
            {t('updateRoles')}
          </Button>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </Form>
    </div>
  );
};
export default UpdateRole;
