import { useState, useEffect } from 'react';
import {
  Select,
  Typography,
  Message,
  RadioGroup,
  FloatButton,
  Button,
} from '../../utils/antd-components/index.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import { Form } from 'antd';
import routesnkt from '../../routes/routes.json';

export default function UpdateProjectAssigns() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchPost = async () => {
        const URL = PATHS.PROJECTASSIGN.GET;
        const data = await ApiUtils(URL + `?_id=${postId}&status=all`);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.response[0]);
        }
      };

      fetchPost();
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);

  useEffect(() => {
    try {
      const fetchCatList = async () => {
        const URL = PATHS.SUBPROJECT.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setProjectList(data.response);
        }
      };

      fetchCatList();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchCatList = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      };

      fetchCatList();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      if (!formData.projectId) {
        return setPublishError(t('select-a-project'));
      }
      setLoading(true);

      if (!formData.userId) {
        return setPublishError(t('selectaUser'));
      }
      const formDataToSend = {
        ...formData,
        projectId:
          typeof formData.projectId === 'object'
            ? formData.projectId._id
            : formData.projectId,
        userId:
          typeof formData.userId === 'object'
            ? formData.userId._id
            : formData.userId,
        projectLeadId:
          typeof formData.projectLeadId === 'object'
            ? formData.projectLeadId._id
            : formData.projectLeadId,
      };
      console.log('formData', formDataToSend);
      if (roles.includes('admin')) {
        const URL = `${PATHS.PROJECTASSIGN.UPDATE}/${postId}`;
        const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.projectsAllocate);
        }
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  const projectId = formData?.projectId?._id
    ? formData.projectId?._id
    : formData.projectId;

  const userId = formData?.userId?._id ? formData.userId?._id : formData.userId;

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('updateProjectsAssign')}
        goBackText={t('back')}
      />
      <Form className="flex flex-col gap-4" onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 sm:flex-column justify-between mt-4">
          <div className=" w-full flex flex-col gap-2">
            <Typography value={t('selectaEmp')} />
            <Form.Item value="userId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(e) => setFormData({ ...formData, userId: e })}
                options={userList}
                selectedValue={userId}
                placeholder={t('selectaUser')}
                label={'first_name'}
                id={'_id'}
              />
            </Form.Item>
          </div>
          <div className=" w-full flex flex-col gap-2">
            <Typography value={t('selectProject')} />
            <Form.Item value="projectId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(e) => setFormData({ ...formData, projectId: e })}
                selectedValue={projectId}
                options={projectList}
                placeholder={t('selectaProject')}
                label={'name'}
                id={'_id'}
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('select-status')} />
          <Form.Item name="status">
            <div className="flex flex-row gap-2">
              <RadioGroup
                onChange={handleRadioChange}
                selectedValue={formData.status}
                t={t}
              />
            </div>
          </Form.Item>
        </div>
        <div className="flex justify-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('updateProjectsAssign')}
          </Button>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </Form>
    </div>
  );
}
