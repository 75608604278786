import React from 'react';
import Lottie from 'react-lottie';
import loaderJson from '../../assets/lottie/loader.json';
import hamburgerJson from '../../assets/lottie/hamburger.json';
import uparrowJson from '../../assets/lottie/uparrow.json';

// Map to associate names with JSON data

const animations = {
  loader: loaderJson,
  hamburger: hamburgerJson,
  uparrow: uparrowJson,
};

// Define TypeScript interface for props

interface LottieControlProps {
  fileName: keyof typeof animations;
  height: number | string;
  width: number | string;
  loop?: boolean;
  autoplay?: boolean;
  center?: boolean;
}

const LottieControl: React.FC<LottieControlProps> = ({
  fileName = 'loader',
  height = 300,
  width = 300,
  loop = true,
  autoplay = true,
  center = true,
}) => {
  let animationData = animations[fileName];

  // Default to loader if no valid animation is found
  if (!animationData) {
    console.error(
      `Animation data for '${fileName}' not found. Falling back to default.`,
    );
    animationData = loaderJson;
  }

  const defaultOptions = {
    loop: loop,
    autoplay: autoplay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return center ? (
    <div className="flex flex-row min-h-screen justify-center items-center">
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  ) : (
    <Lottie options={defaultOptions} height={height} width={width} />
  );
};

export default LottieControl;
