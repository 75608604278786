export const calculateTimeDifferenceInHours = (startTimeStr:string, endTimeStr:string) => {
  if (!endTimeStr) {
    return 0;
  }
  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);

  const timeDiffInMilliseconds = Math.abs(
    endTime.getTime() - startTime.getTime(),
  );
  const hours = Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.ceil(
    (timeDiffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );

  if (minutes <= 15) {
    return `${hours} hr`;
  } else if (minutes <= 45) {
    return `${hours} hr ${30} mins`;
  } else {
    return `${hours + 1} hr`;
  }
};
