import {
  Typography,
  Spinner,
  Input,
  TextInput,
  Button,
  Message,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signInPending, signInFailure } from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import routesnkt from '../../routes/routes.json';

export default function SignUp() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.username ||
      !formData.email ||
      !formData.password ||
      !formData.first_name ||
      !formData.last_name
    ) {
      return setErrorMessage(t('please-fill-out-all-fields'));
    }
    try {
      setLoading(true);
      setErrorMessage(null);
      let payload = {
        ...formData,
        roleId: '65e732d21f1c7ed06805852e',
        status: '1',
      };
      const data = await ApiUtils('/nkt/auth/register', payload, 'POST');
      if (data.status !== 200 && data.status !== 401) {
        dispatch(signInFailure(data.message));
        setErrorMessage(data.message);
        setLoading(false);
      } else if (data.status === 401) {
        dispatch(signInPending(data));
        navigate(routesnkt.userVerify);
      }
      setLoading(false);
      if (data.status == 200) {
        navigate(routesnkt.signIn);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="min-h-screen mt-20">
      <FloatButton.BackTop />
      <div className="flex p-3 max-w-3xl mx-auto flex-col md:flex-row md:items-center gap-5">
        {/* left */}
        <div className="flex-1">
          <Link
            to={routesnkt.home}
            className="font-bold dark:text-white text-4xl"
          >
            <span className="px-2 py-1 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-lg text-white">
              {t('Netkathir Technologies')}
            </span>
          </Link>
          <p className="text-sm mt-5">
            {t(
              'thisIsaNetkathir TechnologiesAdminPanelYouCanSignInWithYourEmailAndPassword',
            )}
          </p>
        </div>
        {/* right */}

        <div className="flex-1">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <Typography value={t('yourUsername')} />
              <TextInput
                type="text"
                placeholder={t('username')}
                id="username"
                onChange={handleChange}
              />
            </div>
            <div>
              <Typography value={t('yourFirstname')} />
              <TextInput
                type="text"
                placeholder={t('firstname')}
                id="first_name"
                onChange={handleChange}
              />
            </div>
            <div>
              <Typography value={t('yourLastname')} />
              <TextInput
                type="text"
                placeholder={t('lastname')}
                id="last_name"
                onChange={handleChange}
              />
            </div>
            <div>
              <Typography value={t('yourEmail')} />
              <TextInput
                type="text"
                placeholder={t('name@companyCom')}
                id="email"
                onChange={handleChange}
              />
            </div>
            <div>
              <Typography value={t('yourPassword')} />
              <Input
                placeholder="**********"
                id="password"
                onChange={handleChange}
              />
              {/* <Input.Password
                type="password"
                placeholder="**********"
                id="password"
                iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
                onChange={handleChange}
              /> */}
            </div>
            <Button
              gradientDuoTone="purpleToBlue"
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner size="small" />
                  <span className="pl-3">{t('loading...')}</span>
                </>
              ) : (
                t('signUp')
              )}
            </Button>
          </form>
          <div className="flex gap-2 text-sm mt-5">
            <span>{t('haveAnAccount')}</span>
            <Link to={routesnkt.signIn} className="text-blue-500">
              {t('signIn')}
            </Link>
          </div>
          {errorMessage && (
            <Message className="mt-5" type="error" message={errorMessage} />
          )}
        </div>
      </div>
    </div>
  );
}
