import React, { FC, ChangeEventHandler } from 'react';
import { Input } from 'antd';

interface CustomInputProps {
  addonAfter?: string;
  addonBefore?: string;
  allowClear?: boolean;
  classNames?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  showCount?: boolean;
  size?: 'large' | 'middle' | 'small';
  status?: boolean | 'error' | 'warning';
  suffix?: React.ReactNode;
  type?: string;
  value?: string;
  autoComplete?: string;
}

export const NumberInput: FC<CustomInputProps> = ({
  addonAfter,
  addonBefore,
  allowClear = true,
  classNames = '',
  defaultValue = '',
  disabled = false,
  id,
  maxLength = 50,
  onChange,
  placeholder = '',
  showCount,
  size = 'large',
  status,
  type,
  value,
  autoComplete,
  suffix,
}) => {
  return (
    <Input
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      defaultValue={defaultValue}
      allowClear={allowClear}
      className={classNames}
      disabled={disabled}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder}
      showCount={showCount}
      size={size}
      id={id}
      status={status as any}
      suffix={suffix}
      type={type}
      value={value}
      autoComplete={autoComplete}
    />
  );
};
