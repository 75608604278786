import { useState, useEffect } from 'react';
import {
  Select,
  TextInput,
  Message,
  Button,
  RadioGroup,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

export default function CreateModuels() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  const [successMessage, setsuccessMessage] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchUsersList = async () => {
        const URL = `${PATHS.USER.GET}?limit=1000`;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      };
      if (roles.includes('admin')) {
        fetchUsersList();
      }
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchProjectList = async () => {
        let URL;
        if (roles.includes('admin') && formData.userId) {
          URL = `${PATHS.PROJECTASSIGN.GET}?userId=${formData.userId}&limit=100`;
        } else {
          URL = `${PATHS.MODULES.PROJECT}?userId=${userId}&limit=100`;
        }
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setProjectList(data.response);
        }
      };
      if (
        (!roles.includes('admin') && userId) ||
        (roles.includes('admin') && formData.userId)
      ) {
        fetchProjectList();
      }
    } catch (error) {
      console.log('err in fetching user list', error.message);
    }
  }, [userId, formData.userId]);

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      let formDataToSend = { ...e };
      const URL = PATHS.MODULES.ADD;
      const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.POST);
      setLoading(true);

      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      } else {
        setsuccessMessage(data.response.messsage);
        setPublishError(null);
        navigate(routesnkt.modules);
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('addModule/Activity')}
        goBackText={t('back')}
      />
      <Form onFinish={handleSubmit} layout="vertical" scrollToFirstError={true}>
        {roles.includes('admin') && (
          <Form.Item
            label={t('name')}
            name="userId"
            rules={[{ required: roles.includes('admin') ? true : false }]}
          >
            <Select
              className={'h-10'}
              onChange={(e) => setFormData({ ...formData, userId: e })}
              id={'userId._id'}
              required={true}
              options={userList}
              placeholder={t('selectaUser')}
            />
          </Form.Item>
        )}

        <Form.Item
          name="projectId"
          label={t('selectaProject')}
          rules={[{ required: true }]}
        >
          <Select
            required={true}
            className={'h-10'}
            options={projectList}
            placeholder={t('selectaProject')}
            label={'projectId.name'}
            id={'projectId._id'}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label={t('modulesName')}
          rules={[{ required: true }]}
        >
          <TextInput
            type="text"
            placeholder={t('modulesName')}
            required
            id="name"
            className="flex-1"
          />
        </Form.Item>

        {roles.includes('admin') && (
          <>
            <Form.Item name="status" label={t('select-status')}>
              <RadioGroup
                onChange={handleRadioChange}
                selectedValue={formData.status}
                t={t}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="content"
          label={t('desc')}
          rules={[{ required: false }]}
        >
          <ReactQuill
            theme="snow"
            placeholder={t('writeSomething')}
            className="h-40 mb-12"
          />
        </Form.Item>
        <div className="justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('addModule/Activity')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
