import { useEffect, FC } from 'react';
import { notification } from 'antd';

interface CustomNotificationProps {
  successMessage: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

export const CustomNotification: FC<CustomNotificationProps> = ({
  successMessage,
  type,
}) => {
  const showToast = () => {
    notification[type]({
      message: successMessage,
      duration: 3,
    });
  };

  useEffect(() => {
    showToast();
  }, []);

  return null;
};
