import { Dropdown, Layout, Select } from 'antd';
import {
  Avatar,
  Button,
  // Message,
  // BadgeRibbon,
} from '../utils/antd-components/index.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../redux/theme/themeSlice.js';
import { signoutSuccess } from '../redux/user/userSlice.js';
import { useEffect, useState } from 'react';
import { useLanguageContext } from '../utils/context/LanguageContext.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import Netkathir from '../assets/images/Netkathir.png';
// import PATHS from '../utils/constants/Paths.tsx';
// import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
// import { ApiUtils } from '../utils/api.jsx';
import routesnkt from '../routes/routes.json';

export default function Header() {
  const authUser = useAuthUser();
  const userId = authUser?._id;
  const userDetails = authUser?.userDetails;
  const { t } = useTranslation();
  const { LANGUAGELIST, onClickLanguageChange, i18n } = useLanguageContext();
  const { Option } = Select;
  // const path = useLocation().pathname;
  const { Header } = Layout;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const signOut = useSignOut();
  // const [clockedInTime, setClockedInTime] = useState(
  //   localStorage.getItem('clockedInTime')
  //     ? new Date(localStorage.getItem('clockedInTime'))
  //     : null,
  // );
  // const [countdown, setCountdown] = useState('00:00:00');
  // const [publishError, setPublishError] = useState(null);

  // useEffect(() => {
  //   let interval;
  //   if (clockedInTime) {
  //     interval = setInterval(() => {
  //       const currentTime = new Date();
  //       const diffInSeconds = Math.floor((currentTime - clockedInTime) / 1000);
  //       const hours = Math.floor(diffInSeconds / 3600);
  //       const minutes = Math.floor((diffInSeconds % 3600) / 60);
  //       const seconds = diffInSeconds % 60;
  //   setCountdown(
  //     `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
  //   );
  // }, 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [clockedInTime]);

  // const handleClockIn = async () => {
  //   setClockedInTime(new Date());
  //   localStorage.setItem('clockedInTime', new Date().toString());
  //   try {
  //     if (!userId) {
  //       return setPublishError(t('error-pls-try-again'));
  //     }
  //     let formDataToSend = {
  //       userId: userId,
  //       clocksInTime: new Date().toString(),
  //     };
  //     const URL = PATHS.USER.CLOCKIN;
  //     const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
  //     if (data.status !== 200) {
  //       setPublishError(data.message);
  //       return;
  //     } else {
  //       setPublishError(null);
  //     }
  //   } catch (error) {
  //     setPublishError(t('something-went-wrong'));
  //   }
  // };

  // const handleClockOut = async () => {
  //   localStorage.removeItem('clockedInTime');
  //   setClockedInTime(null);
  //   setCountdown('00:00:00');
  //   try {
  //     if (!userId) {
  //       return setPublishError(t('error-pls-try-again'));
  //     }
  //     let formDataToSend = {
  //       userId: userId,
  //       clocksOutTime: new Date().toString(),
  //       status: 1,
  //     };
  //     const URL = PATHS.USER.CLOCKOUT;
  //     const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
  //     if (data.status !== 200) {
  //       setPublishError(data.message);
  //       return;
  //     } else {
  //       setPublishError(null);
  //     }
  //   } catch (error) {
  //     setPublishError(t('something-went-wrong'));
  //   }
  // };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (e) => {
    console.log('handleLanguageChange', e);
    setSelectedLanguage(e);
    onClickLanguageChange(e);
  };

  const handleSignout = async () => {
    try {
      signOut();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log('signouterr=====', error);
      console.log(error.message);
    }
  };

  // const formattedDate = new Date().toLocaleDateString('en-US', {
  //   month: 'short', // Abbreviated month name (e.g., "Mar")
  //   day: '2-digit', // Two-digit day of the month (e.g., "21")
  //   year: 'numeric', // Full numeric representation of the year (e.g., "2024")
  // });

  const items = [
    {
      key: '1',
      label: <Link to={routesnkt.profile}>Profile</Link>,
    },
    {
      key: '2',
      label: (
        <>
          <span className="block text-sm">@{userDetails?.first_name}</span>
          <span className="block text-sm font-medium truncate">
            {userDetails?.email}
          </span>
        </>
      ),
    },
    {
      key: '3',
      label: <p onClick={handleSignout}>Sign Out</p>,
    },
  ];
  return (
    <Header
      className="border-b-2 flex flex-row justify-end sticky top-0 z-[100] mb-1i"
      style={{ height: 'max-content' }}
    >
      {window.innerWidth > 768 && (
        <div className="w-full sm:flex sm:items-center">
          <Link to={routesnkt.workLogReport}>
            <img
              src={Netkathir}
              alt="user"
              className="w-full md:w-1/2 md:h-full object-cover"
            />
          </Link>
        </div>
      )}
      <div className="flex gap-2 md:order-2 items-center">
        {/* {userId &&
          (clockedInTime ? (
            <div className="flex flex-row gap-2 items-center">
              <p className="whitespace-nowrap">{formattedDate}</p>
              <div className="text-white bg-gradient-to-br from-purple-600 to-cyan-500 flex flex-row items-center rounded-md">
                <span className="flex items-center transition-all duration-200 rounded-md text-sm px-4 py-2">
                  {countdown}
                </span>
              </div>
              <Button type="primary" onClick={handleClockOut}>
                {t('clock-out')}
              </Button>
            </div>
          ) : (
            // <BadgeRibbon active={'1'}>
            <Button type="primary" onClick={handleClockIn}>
              {t('clock-in')}
            </Button>
            // </BadgeRibbon>
          ))} */}
        <Select
          required={false}
          onChange={handleLanguageChange}
          value={selectedLanguage}
          placeholder={t('select')}
        >
          {LANGUAGELIST &&
            Object.keys(LANGUAGELIST).map((key) => (
              <Option key={key} value={key}>
                {LANGUAGELIST[key].nativeName}
              </Option>
            ))}
        </Select>

        <Button type="primary" onClick={() => dispatch(toggleTheme())}>
          {theme === 'light' ? <FaSun /> : <FaMoon />}
        </Button>
        {userId ? (
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Avatar alt="user" src={userDetails?.imageUrl} rounded />
            </a>
          </Dropdown>
        ) : (
          <Link to={routesnkt.signIn}>
            <Button type="primary" shape="round">
              {t('login')}
            </Button>
          </Link>
        )}
      </div>
      {/* {publishError && (
        <Message className="mt-5" type="error" successMessage={publishError} />
      )} */}
    </Header>
  );
}
