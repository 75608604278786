import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import translationENUS from './locales/en-us.json';
import translationEN from './locales/en.json';
// import translationSP from './locales/es.json';
// import translationFr from './locales/fr.json';
// import translationDe from './locales/de.json';
import translationTa from './locales/ta.json';

const getUserLanguage = localStorage.getItem('selectedLanguage') || 'en';

const resources = {
  // enUS: {
  //   translation: translationENUS,
  // },
  en: {
    translation: translationEN,
  },
  // es: {
  //   translation: translationSP,
  // },
  // de: {
  //   translation: translationDe,
  // },
  // fr: {
  //   translation: translationFr,
  // },
  ta: {
    translation: translationTa,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getUserLanguage,
  fallbackLng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
