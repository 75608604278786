import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface TitleAndBackComponentProps {
  title: string;
  goBackText: React.ReactNode;
}

const TitleAndBackComponent: FC<TitleAndBackComponentProps> = ({
  title,
  goBackText,
}) => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-row gap-4 sm:flex-row justify-between items-center">
      <h1 className="text-center text-3xl my-7 font-semibold">{title}</h1>
      <p
        onClick={goBackHandler}
        className="text-blue-500 underline cursor-pointer hover:underline"
      >
        {goBackText}
      </p>
    </div>
  );
};
export default TitleAndBackComponent;
