import {
  Message,
  Select,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
// import UploadImages from '../utils/UiComponents/commonUpload.jsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import dayjs from 'dayjs';
import routesnkt from '../../routes/routes.json';

export default function UpdateProject() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishError, setPublishError] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [usersList, setUsersList] = useState(null);
  const [mainProjectsList, setMainProjectsList] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);

  const { postId } = useParams();
  // const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      fetchPost();
      fetchUsers();
      fetchMainProjects();
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);

  const fetchPost = async () => {
    const URL = PATHS.SUBPROJECT.GET;
    const data = await ApiUtils(URL + `?_id=${postId}&status=all`);
    if (data.status !== 200) {
      console.log(data.message);
      setPublishError(data.message);
      return;
    } else {
      setPublishError(null);
      setFormData(data.response[0]);
      const { dateRange, status, projectLead, ...restValues } =
        data.response[0];
      const formattedDateRange = dateRange
        ? [dayjs(dateRange[0]), dayjs(dateRange[1])]
        : null;
      form.setFieldsValue({
        ...restValues,
        dateRange: formattedDateRange,
        status: status.toString(),
        projectLead,
      });
    }
  };

  const fetchMainProjects = async () => {
    const URL = PATHS.PROJECT.GET + '?status=1&limit=1000';
    const data = await ApiUtils(URL);
    if (data.status !== 200) {
      console.log(data.message);
      setPublishError(data.message);
      return;
    } else {
      setPublishError(null);
      setMainProjectsList(data.response);
    }
  };

  const fetchUsers = async () => {
    const URL = PATHS.USER.GET + '?status=1&limit=1000';
    const data = await ApiUtils(URL);
    if (data.status !== 200) {
      console.log(data.message);
      setPublishError(data.message);
      return;
    } else {
      setPublishError(null);
      // const filterdUsers = data.users.filter(
      //   (user) => user.roleId?.type === 'Project Manager',
      // );
      // setUsersList(filterdUsers);
      setUsersList(data.users);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const payload = { ...e, _id: postId };
      const URL = `${PATHS.SUBPROJECT.UPDATE}/${postId}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      setLoading(true);

      if (data.status === 200) {
        setsuccessMessage(
          data.response.message || 'Project updated successfully',
        );
        setPublishError(null);
        navigate(routesnkt.subProjects);
      } else {
        setPublishError(data.message || 'Failed to update project');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating project:', error);
      setPublishError('Something went wrong');
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('Update Sub Projects')}
        goBackText={t('back')}
      />
      <Form form={form} onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectamainpj')} />
            <Form.Item name="mainprojectId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(value) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    mainprojectId: value,
                  }));
                }}
                selectedValue={formData.mainprojectId}
                options={mainProjectsList}
                placeholder={t('selectaUser')}
                label={'name'}
                id={'_id'}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('sprojectname')} />
            <Form.Item name="name" rules={[{ required: true }]}>
              <TextInput placeholder={t('sprojectname')} />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectaPm')} />
            <Form.Item name="projectLead" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(value) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    projectLead: value,
                  }));
                }}
                selectedValue={formData.projectLead}
                options={usersList}
                placeholder={t('selectaUser')}
                label={'first_name'}
                id={'_id'}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('projectCode')} />
            <Form.Item name="projectCode" rules={[{ required: true }]}>
              <TextInput placeholder="SSPT,SMT" />
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Typography value={t('client-name')} />
            <Form.Item name="clientName" rules={[{ required: true }]}>
              <TextInput placeholder={t('client-name')} />
            </Form.Item>
          </div>
        </div>

        <div className="flex flex-row gap-2 sm:flex-col justify-between ">
          <Typography value={t('select-status')} />
          <Form.Item name="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="content" rules={[{ required: false }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('updateProjects')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
