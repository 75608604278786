import {
  Table,
  FloatButton,
  Typography,
  // Select,
  DatePicker,
  Button,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import dayjs from 'dayjs';
import { AiOutlineExport } from 'react-icons/ai';
import { saveAs } from 'file-saver'; // Import file-saver for file download
import * as XLSX from 'xlsx'; // Import xlsx for Excel file creation
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export default function DashUsers() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const navigate = useNavigate();
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const [pageCount, setPageCount] = useState(1);
  const [attendance, setAttendance] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [usersId, setUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (roles?.includes('admin')) {
      fetchAttendanceList(searchParams);
      fetchUsersList();
      fetchHolidaysList();
    }
  }, [location.search]);

  const fetchHolidaysList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.HOLIDAY.GET + `?status=all`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setHolidaysList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('err in dash config', error.message);
    }
  };

  const fetchAttendanceList = async () => {
    try {
      setLoading(true);
      const URL = `${PATHS.ATTENDANCE.GET}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setAttendance(data.filteredResponse);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const fetchUsersList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.USER.GET + `?limit=1000&sort=first_name&order=asc`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  // const selectUsers = (selectedList) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (selectedList) {
  //     setUserId(selectedList);
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       userId: selectedList,
  //     });
  //   } else {
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       userId: '',
  //     });
  //   }
  // };

  const handleDateRangeChange = (date) => {
    if (date) {
      const startOfMonth = dayjs(date).startOf('month');
      const endOfMonth = dayjs(date).endOf('month');
      setSelectedMonth(date);
      updateURL({
        startDate: startOfMonth.format('DD-MM-YYYY'),
        endDate: endOfMonth.format('DD-MM-YYYY'),
      });
    } else {
      setSelectedMonth(dayjs());
      updateURL({
        startDate: '',
        endDate: '',
      });
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleExport = () => {
    const exportData = usersList.map((user) => {
      const summary = calculateSummaryColumns(user);
      const userAttendance = {
        'Employee Name': user.username || user.firstName,
        'Working Days': summary.workingDays,
        'Days Present': summary.daysPresent,
        'Days Absent': summary.daysAbsentInMonth,
      };

      const daysInMonth = dayjs(selectedMonth).daysInMonth();
      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = dayjs(selectedMonth).date(day);
        const formattedDate = currentDate.format('DD-MM-YYYY');

        // Find if there's an attendance record covering the current day
        const dayAttendance = attendance.find((att) => {
          if (att?.userId?._id == user._id) {
            const startDate = dayjs(att.startDate || att.date);
            const endDate = dayjs(att.endDate || att.date);

            // Check if the current date is within the attendance date range
            return currentDate.isBetween(startDate, endDate, 'day', '[]'); // '[]' includes start and end date
          }
          return false;
        });

        // Mark the attendance status based on the type
        userAttendance[formattedDate] = dayAttendance
          ? dayAttendance.status === 2
            ? dayAttendance.type === 1
              ? 'Casual Leave'
              : dayAttendance.type === 2
                ? 'Permission'
                : dayAttendance.type === 3
                  ? 'Sick Leave'
                  : dayAttendance.type === 4
                    ? 'Marriage Leave'
                    : dayAttendance.type === 5
                      ? 'Work From Home'
                      : dayAttendance.type === 6
                        ? 'Maternity Leave'
                        : dayAttendance.type === 7
                          ? 'Paternity Leave'
                          : 'Absent'
            : 'Present'
          : 'P';
      }
      return userAttendance;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');
    const workbookOut = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([workbookOut], { type: 'application/octet-stream' }),
      `Attendance_${dayjs(selectedMonth).format('MMMM_YYYY')}.xlsx`,
    );
  };

  const calculateSummaryColumns = (record) => {
    const daysInMonth = dayjs(selectedMonth).daysInMonth();
    const isCurrentMonth = dayjs(selectedMonth).isSame(dayjs(), 'month');
    const lastDayToCount = isCurrentMonth ? dayjs().date() : daysInMonth;
    let workingDays = 0;
    let daysAbsent = 0;
    let daysPresentTillNow = 0;
    let casualLeaveRemaining = 12;
    let sickLeaveRemaining = 6;
    let daysAbsentInMonth = 0;
    let workFromHomeDays = 0; // Track Work from Home days

    const holidayMap = holidaysList.reduce((map, holiday) => {
      const holidayDate = dayjs(holiday.date).format('YYYY-MM-DD');
      map[holidayDate] = true;
      return map;
    }, {});

    // Calculate total leave taken for this user
    const totalLeaveTaken = attendance.reduce(
      (leaveSummary, att) => {
        if (att?.userId?._id === record._id) {
          const currentStartDate = dayjs(att.startDate || att.date);
          const currentEndDate = dayjs(att.endDate || att.date);

          // Calculate leave duration (for newer records with start and end date)
          let leaveDuration = currentEndDate.diff(currentStartDate, 'day') + 1; // +1 to include endDate

          // Adjust based on leave type and accumulate in leaveSummary
          if (att.type === 1) {
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.casualLeave += leaveDuration;
          } else if (att.type === 3) {
            // Check if the session is for a half-day (session 1 or session 2)
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.sickLeave += leaveDuration;
          } else if (att.type === 4) {
            // For marriage leave, also check for half-day sessions
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.marriageLeave += leaveDuration;
          }
        }
        return leaveSummary;
      },
      { casualLeave: 0, sickLeave: 0, marriageLeave: 0 },
    );

    // Deduct the total leaves from the remaining leave balances
    casualLeaveRemaining -= totalLeaveTaken.casualLeave;
    sickLeaveRemaining -= totalLeaveTaken.sickLeave;

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = dayjs(selectedMonth).date(day);
      const dayOfWeek = currentDate.format('ddd');
      const formattedDate = currentDate.format('YYYY-MM-DD');

      if (
        dayOfWeek === 'Sat' ||
        dayOfWeek === 'Sun' ||
        holidayMap[formattedDate]
      ) {
        continue;
      }

      workingDays++;

      const dayAttendance = attendance.find((att) => {
        if (att?.userId?._id === record._id) {
          if (att.startDate && att.endDate) {
            // For newer records with startDate and endDate
            return (
              dayjs(att.startDate).isSameOrBefore(currentDate, 'day') &&
              dayjs(att.endDate).isSameOrAfter(currentDate, 'day')
            );
          } else if (att.date) {
            // For older records with a single date field
            return dayjs(att.date).isSame(currentDate, 'day');
          }
        }
        return false;
      });

      if (
        dayAttendance &&
        dayAttendance.status === 2 &&
        dayAttendance.type === 2
      ) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          daysAbsent += 0.5;
        }
      }
      if (
        dayAttendance &&
        dayAttendance.status === 2 &&
        dayAttendance.type !== 5
      ) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          daysAbsent += 0.5;
          if (day <= lastDayToCount) {
            daysAbsentInMonth += 0.5; // Half-day absence within the month
          }
        } else if (day <= lastDayToCount) {
          daysAbsentInMonth++; // Full-day absence within the month
        }
      }
      // Check for Work from Home type (type === 5) and consider session
      if (dayAttendance && dayAttendance.type === 5) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          workFromHomeDays += 0.5; // Half-day WFO
        } else {
          workFromHomeDays += 1; // Full-day WFO
        }
      }

      if (day <= lastDayToCount) {
        if (!dayAttendance || dayAttendance.status === 2) {
          daysPresentTillNow++;
        }
      }
    }

    const totalDaysAbsent =
      totalLeaveTaken.casualLeave +
      totalLeaveTaken.sickLeave +
      totalLeaveTaken.marriageLeave +
      daysAbsent;

    const daysPresent = daysPresentTillNow - totalDaysAbsent;

    const daysPrsentInMonth = workingDays - daysAbsentInMonth;

    return {
      workingDays,
      daysPresent,
      daysAbsent,
      totalDaysAbsent,
      daysPresentTillNow,
      casualLeaveRemaining,
      sickLeaveRemaining,
      daysAbsentInMonth,
      daysPrsentInMonth,
      workFromHomeDays,
    };
  };

  const summaryColumns = [
    {
      title: t('No of Working Days'),
      key: 'workingDays',
      render: (_, record) => {
        const { workingDays } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {workingDays !== null ? workingDays : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Present'),
      key: 'daysPresent',
      render: (_, record) => {
        const { daysPrsentInMonth } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysPrsentInMonth !== null ? daysPrsentInMonth : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Absent'),
      key: 'totalDaysAbsent',
      render: (_, record) => {
        const { daysAbsentInMonth } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysAbsentInMonth !== null ? daysAbsentInMonth : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of WFO '),
      key: 'workFromHomeDays',
      render: (_, record) => {
        const { workFromHomeDays } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {workFromHomeDays !== null ? workFromHomeDays : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  const baseColumns = [
    {
      title: t('Employees Name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      filteredValue: null,
      fixed: 'left',
    },
  ];

  // const dynamicColumns = generateDaysColumns();

  // const disabledDate = (current) => {
  //   const currentDate = new Date();
  //   const startMonth = new Date(currentDate.getFullYear(), 6);

  //   return (
  //     (current && current.toDate() > currentDate) ||
  //     current.toDate() < startMonth
  //   );
  // };
  const columns = [
    ...baseColumns,
    // ...dynamicColumns
    ...summaryColumns,
  ];
  return (
    <div className="w-2/3 md:mx-auto p-3">
      <FloatButton.BackTop />
      <div className="flex flex-col md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
        <div className="flex md:w-1/2 flex-row items-start gap-2">
          <div className="w-full  flex flex-col gap-2">
            <Typography value={t('Select Month')} />
            <DatePicker
              onChange={handleDateRangeChange}
              selectedValue={selectedMonth}
              //disabledDate={disabledDate}
              picker="month"
              format="MMM-YYYY"
              className={'w-1/2'}
            />
          </div>
          {/* <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectUser')} />
            <Select
              options={usersList}
              className={'w-full'}
              onChange={selectUsers}
              placeholder={t('selectaUser')}
            />
          </div> */}
        </div>
        <Button
          type="primary"
          className="items-center justify-center"
          icon={<AiOutlineExport />}
          onClick={handleExport}
        >
          {t('Export')}
        </Button>
      </div>

      {roles?.includes('admin') && (
        <div className="w-[100%]">
          <div className="overflow-x-auto">
            <Table
              dataSource={usersList}
              columns={columns}
              className="w-full"
              onChange={handleStatusFilterChange}
              pagination={{
                current: pageCount,
                pageSize: 500,
              }}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
