import {
  Message,
  Typography,
  TextInput,
  Input,
  Button,
  Tabs,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { signInStart, signInStop } from '../../../redux/user/userSlice.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import PATHS from '../../../utils/constants/Paths.tsx';
import routesnkt from '../../../routes/routes.json';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';

export default function DashSettings() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const { loading } = useSelector((state) => state.user);
  const [updateUserSuccess, setUpdateUserSuccess] = useState(null);
  const [updateUserError, setUpdateUserError] = useState(null);
  const [formData, setFormData] = useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateUserError(null);
    setUpdateUserSuccess(null);
    const regex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?]).{6,30}$',
    );
    if (Object.keys(formData).length === 0) {
      setUpdateUserError(t('no-changes-made'));
      return;
    } else if (
      !formData.oldpassword ||
      !formData.newpassword ||
      !formData.confirmpassword
    ) {
      setUpdateUserError(t('please-fill-all-fields'));
      return;
    } else if (formData.newpassword !== formData.confirmpassword) {
      setUpdateUserError(t('new-password-and-confirm-password-must-match'));
      return;
    } else if (!regex.test(formData.newpassword)) {
      setUpdateUserError(t('password-validation-failed'));
      return;
    }
    try {
      const payload = {
        _id: userId,
        oldpassword: formData.oldpassword,
        newpassword: formData.newpassword,
      };
      dispatch(signInStart());
      const URL = PATHS.AUTH.CHANGEPASS;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);

      if (data.status !== 200) {
        setUpdateUserError(data.message);
      } else if (data.status === 200) {
        setFormData({
          oldpassword: '',
          newpassword: '',
          confirmpassword: '',
        });
        setUpdateUserSuccess(t('password-updated-successfully'));
        navigate(routesnkt.workLogReport);
      }
      dispatch(signInStop());
    } catch (error) {
      dispatch(signInStop());
    }
  };

  const items = [
    {
      key: '1',
      label: t('changePassword'),
      children: (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('oldPassword')} />

            <TextInput
              type="text"
              id="oldpassword"
              placeholder={t('newPassword')}
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('newPassword')} />
            <Input
              placeholder={t('newPassword')}
              id="newpassword"
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('confirmPassword')} />
            <Input
              placeholder={t('confirmPassword')}
              id="confirmpassword"
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-5">
            <Button
              type="primary"
              // gradientDuoTone="purpleToBlue"
              outline
              disabled={loading}
            >
              {loading ? 'Loading...' : t('update')}
            </Button>
          </div>
        </form>
      ),
    },
    {
      key: '2',
      label: t('account'),
      children: t('account'),
    },
    {
      key: '3',
      label: t('privacy'),
      children: t('privacy'),
    },
  ];

  return (
    <div className="p-3 md:mx-auto w-1/4">
      <FloatButton.BackTop />
      <Tabs defaultActiveKey="1" items={items} />
      {updateUserError && (
        <Message className="mt-5" type="error" message={updateUserError} />
      )}
      {updateUserSuccess && (
        <Message successMessage={updateUserSuccess} type="success" />
      )}
    </div>
  );
}
