import {
  TextInput,
  Message,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
// import { PATHS, METHOD_TYPES } from '../../utils/constants/index.js';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

const CreateConfig = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { RangePicker } = DatePicker;
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    console.log('Received values of form: ', e);
    try {
      const payload = { ...e };
      if (roles.includes('admin')) {
        const URL = PATHS.CONFIG.ADD;
        const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
        setLoading(true);
        console.log('API Response:', data);
        if (data.status !== 200) {
          console.log('Error response:', data);
          setPublishError(data.message);
          return;
        } else {
          // Check if data.response exists before accessing its properties
          if (data.response && data.response.messsage) {
            setsuccessMessage(data.response.messsage);
          } else {
            // If response or message doesn't exist, set a default error message
            setPublishError('Unknown error occurred');
          }
          setPublishError(null);
          navigate(routesnkt.config);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('Axios error:', error);
      setPublishError(t('something-went-wrong'));
    }
  };
  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('create-config')}
        goBackText={t('back')}
      />
      <Form onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('name-0')} />
            <Form.Item name="name" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-name')} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('value')} />
            <Form.Item name="value" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-value-0')} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('code')} />
            <Form.Item name="code" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-value')} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 sm:flex-col justify-between ">
          <Typography value={t('select-status')} />
          <Form.Item value="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('createConfig')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default CreateConfig;
