import {
  Button,
  Table,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
// import { Pie } from '@ant-design/charts';
import {
  HiAnnotation,
  HiArrowNarrowUp,
  HiDocumentText,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import { ApiUtils } from '../../utils/api.jsx';
import { Link } from 'react-router-dom';
import DefaultImage from '../../assets/images/default-user.png';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
// import { populate12HrFormat } from '../../utils/reUsable/populatedHrFormat.tsx';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import LottieControl from '../../utils/UiComponents/lottie.tsx';
import routesnkt from '../../routes/routes.json';

const Dashboard = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;
  const roles = authUser.rolesType;
  const [users, setUsers] = useState([]);
  const [userTimings, setUserTimings] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [userTimingsCount, setUserTimingsCount] = useState(0);
  const [lastMonthUsers, setLastMonthUsers] = useState(0);
  const [lastMonthProjects, setLastMonthProjects] = useState(0);
  const [lastMonthUserTimings, setLastMonthUserTimings] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const URL = `${PATHS.USER.GET}?limit=5&expand=recent`;
        const data = await ApiUtils(URL);
        <ScrollToTop />;

        if (data.status === 200) {
          setUsers(data.users);
          setTotalUsers(data.totalUsers);
          setLastMonthUsers(data.lastMonthUsers);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const URL = `${PATHS.PROJECT.GET}?limit=5&expand=recent`;
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          setProjects(data.response);
          setTotalProjects(data.totalCount);
          setLastMonthProjects(data.lastMonthProjects);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('dashb==', error.message);
      }
    };

    const fetchUserTimings = async () => {
      try {
        setLoading(true);
        const URL = `${PATHS.USER.USERTIMINGS}?limit=5&expand=recent`;
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          setUserTimings(data.response);
          setUserTimingsCount(data.totalCount);
          setLastMonthUserTimings(data.lastMonthUserTimings);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('fetchemcnt==', error.message);
      }
    };
    if (roles.includes('admin')) {
      fetchUsers();
      fetchProjects();
      fetchUserTimings();
    }
  }, [userDetails]);

  const columns = [
    {
      title: t('userName'),
      dataIndex: ['userId', 'first_name'],
      key: ['userId', 'first_name'],
      render: (firstName) => <p className="line-clamp-2">{firstName}</p>,
    },
    // {
    //   title: t('clocked-in'),
    //   dataIndex: 'clocksInTime',
    //   key: 'clocksInTime',
    //   render: (clocksInTime) => populate12HrFormat(clocksInTime),
    // },
    {
      title: t('created-on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
  ];

  const columnsProjects = [
    {
      title: t('project-image'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (imageUrl) => (
        <img
          src={imageUrl ? imageUrl : DefaultImage}
          alt="user"
          className="w-14 h-10 rounded-md bg-gray-500"
        />
      ),
    },
    {
      title: t('project-name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('client-name'),
      dataIndex: 'clientName',
      key: 'clientName',
    },
  ];

  const columnsUsers = [
    {
      title: t('userImage'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (imageUrl) => (
        <img
          src={imageUrl ? imageUrl : DefaultImage}
          alt="user"
          className="w-5 h-5 rounded-full bg-gray-500"
        />
      ),
    },
    {
      title: t('userName'),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: t('created-on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
  ];

  // const data = [
  //   {
  //     type: 'Appliances',
  //     value: 27,
  //   },
  //   {
  //     type: 'Electronics',
  //     value: 25,
  //   },
  //   {
  //     type: 'Clothing',
  //     value: 18,
  //   },
  //   {
  //     type: 'Shoes',
  //     value: 15,
  //   },
  //   {
  //     type: 'Food',
  //     value: 10,
  //   },
  //   {
  //     type: 'Cosmetice',
  //     value: 5,
  //   },
  // ];

  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: 'value',
  //   colorField: 'type',
  //   radius: 1,
  //   innerRadius: 0.5,
  //   label: {
  //     type: 'inner',
  //     offset: '-50%',
  //     content: '{value}%',
  //     style: {
  //       textAlign: 'center',
  //       fontSize: 16,
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: 'element-selected',
  //     },
  //     {
  //       type: 'element-active',
  //     },
  //   ],
  //   statistic: {
  //     title: false,
  //     content: {
  //       style: {
  //         whiteSpace: 'pre-wrap',
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //         fontSize: 18,
  //       },
  //       content: '18,935\nsales',
  //     },
  //   },
  // };

  return (
    <div className="p-3 md:mx-auto">
      <FloatButton.BackTop />

      <div className="flex-wrap flex gap-4 justify-center mt-10">
        <div className="flex flex-col p-3 dark:bg-slate-800 gap-4 md:w-72 w-full rounded-md shadow-md">
          <div className="flex justify-between">
            <div className="">
              <h3 className="text-gray-500 text-md ">{t('totalUsers')}</h3>
              <p className="text-2xl">{totalUsers}</p>
            </div>
            <HiOutlineUserGroup className="bg-teal-600  text-white rounded-full text-5xl p-3 shadow-lg" />
          </div>
          <div className="flex  gap-2 text-sm">
            <span className="text-green-500 flex items-center">
              <LottieControl
                fileName="uparrow"
                height={20}
                width={10}
                center={false}
              />
              {lastMonthUsers}
              <div className="text-gray-500">{t('lastMonth')}</div>
            </span>
          </div>
        </div>
        <div className="flex flex-col p-3 dark:bg-slate-800 gap-4 md:w-72 w-full rounded-md shadow-md">
          <div className="flex justify-between">
            <div className="">
              <h3 className="text-gray-500 text-md">{t('users-present')}</h3>
              <p className="text-2xl">{userTimingsCount}</p>
            </div>
            <HiAnnotation className="bg-indigo-600  text-white rounded-full text-5xl p-3 shadow-lg" />
          </div>
          <div className="flex  gap-2 text-sm">
            <span className="text-green-500 flex items-center">
              <HiArrowNarrowUp />
              {lastMonthUserTimings}
            </span>
            <div className="text-gray-500">{t('lastMonth')}</div>
          </div>
        </div>
        <div className="flex flex-col p-3 dark:bg-slate-800 gap-4 md:w-72 w-full rounded-md shadow-md">
          <div className="flex justify-between">
            <div className="">
              <h3 className="text-gray-500 text-md">{t('total-projects')} </h3>
              <p className="text-2xl">{totalProjects}</p>
            </div>
            <HiDocumentText className="bg-lime-600  text-white rounded-full text-5xl p-3 shadow-lg" />
          </div>
          <div className="flex  gap-2 text-sm">
            <span className="text-green-500 flex items-center">
              <HiArrowNarrowUp />
              {lastMonthProjects}
            </span>
            <div className="text-gray-500">{t('lastMonth')}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 py-3 mx-auto justify-center">
        <div className="flex flex-col w-full md:w-auto shadow-md p-2 rounded-md dark:bg-gray-800">
          <div className="flex justify-between  p-3 text-sm font-semibold">
            <h1 className="text-center p-2">{t('recentUsers')}</h1>
            <Button outline gradientDuoTone="purpleToPink">
              <Link to={routesnkt.users}>{t('seeAll')}</Link>
            </Button>
          </div>
          <Table
            dataSource={users}
            columns={columnsUsers}
            rowKey={(record) => record._id}
            loading={loading}
          />
        </div>

        {/* <div className="flex flex-col w-full md:w-auto shadow-md p-2 rounded-md dark:bg-gray-800">
          <div className="flex justify-between  p-3 text-sm font-semibold">
            <h1 className="text-center p-2">{t('recentUsers')}</h1>
            <Button outline gradientDuoTone="purpleToPink">
              <Link to={routesnkt.users}>{t('seeAll')}</Link>
            </Button>
          </div>
          <Pie {...config} />
        </div> */}

        <div className="flex flex-col w-full md:w-auto shadow-md p-2 rounded-md dark:bg-gray-800">
          <div className="flex justify-between  p-3 text-sm font-semibold">
            <h1 className="text-center p-2">{t('users-present')}</h1>
            <Button outline gradientDuoTone="purpleToPink">
              <Link to={routesnkt.clockIns}>{t('seeAll')}</Link>
            </Button>
          </div>

          <Table
            dataSource={userTimings}
            columns={columns}
            rowKey={(record) => record._id}
            loading={loading}
          />
        </div>
        <div className="flex flex-col w-full md:w-auto shadow-md p-2 rounded-md dark:bg-gray-800">
          <div className="flex justify-between  p-3 text-sm font-semibold">
            <h1 className="text-center p-2"> {t('last-month-projects')} </h1>
            <Button outline gradientDuoTone="purpleToPink">
              <Link to={routesnkt.projects}>{t('seeAll')}</Link>
            </Button>
          </div>
          <Table
            dataSource={projects}
            columns={columnsProjects}
            rowKey={(record) => record._id}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
