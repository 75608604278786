import { useEffect, useState } from 'react';

const InstallPrompt = () => {
  const [isIos, setIsIos] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
    const isInStandaloneMode =
      'standalone' in window.navigator && window.navigator.standalone;

    setIsIos(isIosDevice);
    setIsStandalone(isInStandaloneMode);
  }, []);

  const showInstallInstructions = () => {
    alert(
      'To install this app, tap the share icon (a square with an upward arrow) in the Safari toolbar and then tap "Add to Home Screen".',
    );
  };

  if (isIos && !isStandalone) {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
        }}
      >
        <button
          onClick={showInstallInstructions}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            background: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          Install App
        </button>
      </div>
    );
  }

  return null;
};

export default InstallPrompt;
