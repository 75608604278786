import {
  Message,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useState } from 'react';
import { Form } from 'antd';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';

const CreateProjects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    type: '',
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    // e.preventDefault();
    console.log('valuessss', e);
    try {
      const payload = { ...e, status: formData.status };
      setLoading(true);
      if (roles.includes('admin')) {
        const URL = PATHS.ROLES.ADD;
        const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.roles);
        }
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  // const handleTextChange = (e) => {
  //   setFormData({ ...formData, type: e.target.value });
  // };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent title={t('createRole')} goBackText={t('back')} />
      <Form className="flex flex-col gap-4" onFinish={handleSubmit}>
        {/* <form className="flex flex-col gap-4" onSubmit={handleSubmit}> */}
        <div className="flex flex-col gap-2 sm:flex-col justify-between">
          <Typography value={t('roleName')} />
          <Form.Item name="type" rules={[{ required: true }]}>
            <TextInput
              placeholder={t('roleName')}
              className="flex-1"
              // onChange={handleTextChange}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <Typography value={t('selectactive')} />
          <div className="flex flex-row gap-4">
            <Form.Item value="status" rules={[{ required: true }]}>
              <RadioGroup
                onChange={handleRadioChange}
                selectedValue={formData.status}
                t={t}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end w-full">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {t('createRole')}
            </Button>
          </div>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
      {/* </form> */}
    </div>
  );
};
export default CreateProjects;
