import {
  Spinner,
  Table,
  Select,
  Space,
  FloatButton,
  Popconfirm,
} from '../../../utils/antd-components/index.tsx';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../../../utils/api.jsx';
import DefaultImage from '../../../assets/images/default-user.png';
import { STATUSLIST } from '../../../utils/constants/index.js';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { populate12HrFormat } from '../../../utils/reUsable/populatedHrFormat.tsx';
import { calculateTimeDifferenceInHours } from '../../../utils/reUsable/calculateTimeDifferenceInHrs.tsx';
import { CiEdit } from 'react-icons/ci';
import { MdDeleteOutline } from 'react-icons/md';
import routesnkt from '../../../routes/routes.json';

export default function DashUsers() {
  const { t } = useTranslation();
  const translatedSTATUSLIST = STATUSLIST(t);
  const authUser = useAuthUser();
  const userId = authUser._id;
  const roles = authUser.rolesType;
  const [usersList, setUsersList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageDataLimit, setPageDataLimit] = useState(10);

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        setLoading(true);
        const URL = PATHS.USER.USERTIMINGS;
        let data;
        if (roles.includes('admin')) {
          data = await ApiUtils(
            URL + `?status=${filter}&limit=${pageDataLimit}`,
          );
        } else {
          data = await ApiUtils(
            URL + `?status=${filter}&userId=${userId}&limit=${pageDataLimit}`,
          );
        }
        if (data.status === 200) {
          setUsersList(data.response);
          setTotalCount(data.totalCount);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (roles.includes('admin')) {
      if (userId) {
        fetchUsersList();
      }
    }
  }, [userId, filter]);

  const handleShowMore = async (page, pageSize) => {
    try {
      setPageCount(page);
      setPageDataLimit(pageSize);
      setLoading(true);
      const URL = PATHS.USER.USERTIMINGS;
      let data;
      if (roles.includes('admin')) {
        data = await ApiUtils(
          URL + `?status=${filter}&page=${page}&limit=${pageDataLimit}`,
        );
      } else {
        data = await ApiUtils(
          URL +
            `?status=${filter}&userId=${userId}&page=${page}&limit=${pageDataLimit}`,
        );
      }
      if (data.status === 200) {
        setUsersList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.USER.USERTIMINGSDELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setUsersList((prev) => prev.filter((user) => user._id !== id));
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: t('created-on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: t('userImage'),
      dataIndex: ['userId', 'imageUrl'],
      key: 'userId',
      render: (imageUrl, record) => (
        <img
          src={imageUrl ? imageUrl : DefaultImage}
          alt={record.userId?.first_name}
          className="w-10 h-10 object-cover bg-gray-500 rounded-full"
        />
      ),
    },
    {
      title: t('userName'),
      dataIndex: ['userId', 'first_name'],
      key: 'userId',
    },
    {
      title: t('clock-in'),
      dataIndex: 'clocksInTime',
      key: 'clocksInTime',
      render: (clocksInTime) => populate12HrFormat(clocksInTime),
    },
    {
      title: t('clock-out'),
      dataIndex: 'clocksOutTime',
      key: 'clocksOutTime',
      render: (clocksOutTime) => populate12HrFormat(clocksOutTime),
    },
    {
      title: t('hours'),
      key: 'hours',
      render: (text, record) => (
        <div className="flex flex-row items-center">
          {calculateTimeDifferenceInHours(
            record.clocksInTime,
            record.clocksOutTime,
          )}
        </div>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (text, record) => (
        <Popconfirm
          title={t('are-you-sure-you-want-to-delete-this-module')}
          onConfirm={() => handleDeleteUser(record._id)}
          okText={t('yes-im-sure')}
          cancelText={t('no-cancel')}
        >
          <MdDeleteOutline size={20} color="red" />
        </Popconfirm>
      ),
    },
    {
      title: t('edit'),
      key: 'edit',
      render: (text, record) => (
        <Space size="middle" align={'center'}>
          <Link to={`${routesnkt.updateUsertime}${record._id}`}>
            <CiEdit size={20} color="blue" />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2 mt-5">
        <Select
          required={true}
          onChange={(e) => setFilter(e.target.value)}
          options={translatedSTATUSLIST}
          placeholder={t('select')}
          label="name"
          className={'w-1/4'}
        />
      </div>
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner size="large" />
        </div>
      ) : (
        <>
          {usersList.length > 0 ? (
            <>
              <Table
                dataSource={usersList}
                columns={columns}
                rowKey={(record) => record._id}
                pagination={{
                  pageSize: pageDataLimit,
                  total: totalCount,
                  current: pageCount,
                  onChange: (page, pageSize) => {
                    handleShowMore(page, pageSize);
                  },
                }}
                loading={loading}
              />
            </>
          ) : (
            <p>{t('youHaveNoUsersYet')}</p>
          )}
        </>
      )}
    </div>
  );
}
