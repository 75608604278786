import React from 'react';
import { Popconfirm } from 'antd';
import { ReactNode, FC } from 'react';

// Define a TypeScript interface for the props
interface CustomPopconfirmProps {
  title: string;
  onConfirm: () => void; // Adjust the signature if needed, based on how it's used
  okText?: string;
  cancelText?: string;
  children?: ReactNode; // ReactNode covers anything that can be rendered
}

export const CustomPopconfirm: FC<CustomPopconfirmProps> = ({
  title,
  onConfirm,
  okText,
  cancelText,
  children,
}) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText={okText}
      cancelText={cancelText}
    >
      {children}
    </Popconfirm>
  );
};
