import { useState, useEffect } from 'react';
import {
  Typography,
  Select,
  TextInput,
  Message,
  DatePicker,
  Button,
  Modal,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import dayjs from 'dayjs';
import { Form } from 'antd';
import routesnkt from '../../routes/routes.json';
import { IoAddCircleSharp } from 'react-icons/io5';

export default function CreateWorklogs() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  const [successMessage, setsuccessMessage] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [modulesList, setModulesList] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    status: 1,
    date: dayjs().format('YYYY-MM-DD'),
  });

  const [publishError, setPublishError] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');

  const handleAddModuleClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    if (!newModuleName || newModuleName.length < 3) {
      setError('Module name must be at least 3 characters long');
      return;
    }
    try {
      const payload = {
        name: newModuleName, // Set your new module name here
        projectId: formData.projectId, // Use the selected project ID
        userId: formData.userId, // Use the selected user ID
      };
      const URL = PATHS.MODULES.ADD;
      const data = await ApiUtils(URL, payload, 'POST');
      if (data.status !== 200) {
        setPublishError(data.message);
      } else {
        // Add the new module to the list or update it in your state
        setPublishError(null);
        setModulesList((prevModulesList) => [
          ...prevModulesList,
          data.response,
        ]);
        console.log('data.response', data.response);
        setFormData({ ...formData, moduleId: data.response._id });
        form.setFieldsValue({ moduleId: data.response._id });
      }
    } catch (error) {
      setPublishError('Error adding module');
    }

    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  useEffect(() => {
    try {
      const fetUsersList = async () => {
        const URL = PATHS.USER.GET;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      };
      if (roles.includes('admin')) {
        fetUsersList();
      }
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchProjectList = async () => {
        let URL;
        if (roles.includes('admin') && formData.userId) {
          URL = `${PATHS.PROJECTASSIGN.GET}?userId=${formData.userId}&limit=100`;
        } else {
          URL = `${PATHS.MODULES.PROJECT}?userId=${userId}&limit=100`;
        }
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setProjectList(data.response);
        }
      };
      if (
        (!roles.includes('admin') && userId) ||
        (roles.includes('admin') && formData.userId)
      ) {
        fetchProjectList();
      }
    } catch (error) {
      console.log('err in fetching user list', error.message);
    }
  }, [userId, formData.userId]);

  useEffect(() => {
    try {
      const fetchModuleList = async () => {
        let URL;
        if (roles.includes('admin') && form.getFieldValue('userId')) {
          URL = `${PATHS.MODULES.GET}?userId=${form.getFieldValue('userId')}&projectId=${form.getFieldValue('projectId')}&limit=100`;
        } else {
          URL = `${PATHS.MODULES.GET}?userId=${userId}&projectId=${form.getFieldValue('projectId')}&limit=100`;
        }
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setModulesList(data.response);
        }
      };
      if (
        !roles.includes('admin') &&
        userId &&
        form.getFieldValue('projectId')
      ) {
        fetchModuleList();
      } else if (
        roles.includes('admin') &&
        form.getFieldValue('userId') &&
        form.getFieldValue('projectId')
      ) {
        fetchModuleList();
      }
    } catch (error) {
      console.log('err in fetching user list', error.message);
    }
  }, [userId, form.getFieldValue('userId'), form.getFieldValue('projectId')]);

  const handleSubmit = async (e) => {
    console.log('formsubmited', e);
    setLoading(true);
    try {
      let formDataToSend = {
        ...e,
        date: dayjs(e.date).format('YYYY-MM-DD'),
      };

      const URL = PATHS.WORKLOG.ADD;
      const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.POST);
      setLoading(true);
      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      } else {
        setsuccessMessage(data.response.messsage);
        setPublishError(null);
        navigate(routesnkt.workLogReport);
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleTimeNameChange = (e) => {
    const value = e.target.value;
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]).[0-5][0-9]$/;

    if (timeRegex.test(value)) {
      form.setFieldsValue({ time: e.target.value });
      setError(null);
    } else {
      setError('Invalid time format');
      console.log('Invalid time format');
    }
  };

  const handleProjectChange = (e) => {
    setFormData({ ...formData, projectId: e, moduleId: '' }),
      form.setFieldValue('moduleId', null);
  };

  const handleModuleChange = (moduleId) => {
    setFormData({ ...formData, moduleId }); // Update the moduleId in formData
    form.setFieldsValue({ moduleId }); // Update the form field value
  };

  const handleUserChange = (e) => {
    setFormData({ ...formData, userId: e, projectId: '', moduleId: '' }),
      form.setFieldValue('projectId', null);
    form.setFieldValue('moduleId', null);
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent title={t('add_worklog')} goBackText={t('back')} />
      <Form
        className="flex flex-col gap-4"
        form={form}
        initialValues={{ date: dayjs() }}
        onFinish={handleSubmit}
      >
        <div className="flex flex-col gap-4 justify-between mt-6">
          {roles.includes('admin') && (
            <div className="w-full flex flex-col gap-2 mt-4">
              <Typography value={t('selectUser')} />
              <Form.Item
                name="userId"
                rules={[{ required: roles.includes('admin') ? true : false }]}
              >
                <Select
                  className={'h-10'}
                  required={true}
                  onChange={(e) => handleUserChange(e)}
                  options={userList}
                  placeholder={t('selectaUser')}
                  id={'userId._id'}
                />
              </Form.Item>
            </div>
          )}
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('selectProject')} />
            <Form.Item name="projectId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                disabled={roles.includes('admin') && !formData.userId}
                onChange={(e) => handleProjectChange(e)}
                options={projectList}
                onClear={(e) => setFormData({ ...formData, projectId: e })}
                placeholder={t('selectaProject')}
                label={'projectId.name'}
                id={'projectId._id'}
                selectedValue={form.getFieldValue('projectId')}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col md:flex-col md:justify-between items-end gap-2 py-4 mt-2">
          <div className="w-full flex flex-col gap-2">
            <div style={{ display: 'flex', gap: 10, color: 'green' }}>
              <Typography value={t('modulesName')} />{' '}
              {formData.projectId && (
                <IoAddCircleSharp
                  style={{ fontSize: 25 }}
                  onClick={handleAddModuleClick}
                />
              )}
            </div>
            <Form.Item name="moduleId" rules={[{ required: true }]}>
              <div style={{ display: 'flex', gap: 10 }}>
                <Select
                  required={true}
                  disabled={!formData.projectId}
                  onChange={(moduleId) => handleModuleChange(moduleId)}
                  selectedValue={form.getFieldValue('moduleId')}
                  options={modulesList}
                  placeholder={t('selectaModulesName')}
                  label={'name'}
                  id={'_id'}
                  className={'w-full h-10'}
                />
              </div>
            </Form.Item>
          </div>
          <Modal
            title={t('Add New Module')}
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          >
            <TextInput
              placeholder={t('Module Name')}
              value={newModuleName}
              onChange={(e) => setNewModuleName(e.target.value)}
            />
            {error && <p className="text-red-500">{error}</p>}
          </Modal>
        </div>
        <div className="flex flex-row gap-4 justify-between mt-3">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('select-date')} />
            <Form.Item name="date" rules={[{ required: true }]}>
              <DatePicker
                placeholder="Select Date"
                format="DD-MM-YYYY"
                required
                id="date"
                className="h-10 w-full"
                defaultValue={dayjs()}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectduration')} />
            <Form.Item name="time" rules={[{ required: true }]}>
              <TextInput
                type="text"
                placeholder={t('hhmm')}
                required
                id="time"
                maxLength={5}
                className="flex-1"
                onChange={handleTimeNameChange}
              />
            </Form.Item>
            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('work-log-Title')} />
          <Form.Item name="name" rules={[{ required: true }]}>
            <TextInput
              type="text"
              placeholder={t('work-log-Title')}
              required
              id="name"
              className="flex-1"
              maxLength={100}
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('workLogDesc')} />
          <Form.Item name="content" rules={[{ required: true }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className=" flex justify-end mt-4">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('add_worklog')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
