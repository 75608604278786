import {
  Message,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

export default function UpdateConfig() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishError, setPublishError] = useState(null);
  const [formData, setFormData] = useState({
    status: 1,
  });
  const [successMessage, setsuccessMessage] = useState(null);
  const { postId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('postId');
    try {
      const fetchPost = async () => {
        console.log('postId', postId);
        const URL = PATHS.CONFIG.GET;
        const data = await ApiUtils(URL + `?_id=${postId}&status=all`);
        console.log('data', data);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.response[0]);
          console.log('formData', formData);
          const { status, ...restValues } = data.response[0];
          console.log('restValues', restValues);
          form.setFieldsValue({
            ...restValues,
            status: status.toString(), // Convert status to string
          });
        }
      };

      fetchPost();
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const payload = { ...e, _id: postId };
      const URL = `${PATHS.CONFIG.UPDATE}/${postId}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      console.log('Datts==========', data);
      setLoading(true);

      if (data.status === 200) {
        setsuccessMessage(data.message || 'Config updated successfully');
        setPublishError(null);
        navigate(routesnkt.config);
      } else {
        setPublishError(data.message || 'Failed to update config');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating config:', error);
      setPublishError('Something went wrong');
    }
  };
  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent title={t('updateConfig')} goBackText={t('back')} />
      <Form form={form} onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('name-0')} />
            <Form.Item name="name" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-name')} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('value')} />
            <Form.Item name="value" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-value-0')} />
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Typography value={t('code')} />
            <Form.Item name="code" rules={[{ required: true }]}>
              <TextInput placeholder={t('enter-value')} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-row gap-2 sm:flex-col justify-between ">
          <Typography value={t('select-status')} />
          <Form.Item name="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('updateConfig')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
