import React, { FC } from 'react';
import { TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import type { TimePickerProps } from 'antd';

interface CustomTimePickerProps
  extends Omit<TimePickerProps, 'value' | 'onChange'> {
  selectedValue?: Dayjs;
  onChange?: (time: Dayjs | null, timeString: string) => void;
  readOnly?: boolean;
}

export const CustomTimePicker: FC<CustomTimePickerProps> = ({
  selectedValue,
  onChange,
  format = 'HH:mm a',
  placeholder = 'Select time',
  disabled,
  className,
  id = '_id',
  readOnly = true,
}) => {
  return (
    <TimePicker
      className={className}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      readOnly={readOnly}
      id={id}
      disabled={disabled}
    />
  );
};
