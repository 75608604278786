import { SearchInput } from '../antd-components/index.tsx';
import { IoSearch } from 'react-icons/io5';
// import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash'; // Import debounce from lodash

const SearchableColumn = ({ dataIndex }) => {
  const navigate = useNavigate();

  // Debounce the handleSearch function
  const debouncedHandleSearch = debounce((selectedKeys, key) => {
    try {
      const searchParams = new URLSearchParams(location.search);
      updateURL({
        ...Object.fromEntries(searchParams),
        [key]: selectedKeys,
      });
    } catch (error) {
      console.log('err in handle search', error.message);
    }
  }, 300); // Adjust the debounce delay (in milliseconds) as needed

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const getColumnSearchProps = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <SearchInput
          placeholder={`Search ${dataIndex}`}
          onChange={(e) => debouncedHandleSearch(e.target.value, dataIndex)}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <IoSearch style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // render: (text) =>
    //   searchText ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text.toString()}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  return getColumnSearchProps();
};

export default SearchableColumn;
