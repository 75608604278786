import React, { FC, ReactNode } from 'react';
import { Space } from 'antd';

interface CommonAntdSpaceProps {
  direction?: 'horizontal' | 'vertical';
  size?: 'small' | 'middle' | 'large' | undefined;
  align?: 'start' | 'end' | 'center' | 'baseline';
  children: ReactNode;
}

export const CommonAntdSpace: FC<CommonAntdSpaceProps> = ({
  direction = 'horizontal',
  size = 'small',
  align = 'center',
  children,
}) => {
  return (
    <Space direction={direction} size={size} align={align}>
      {children}
    </Space>
  );
};
